import { Col, Form, Row, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import barchart from '../assets/images/Bar Chart.svg'
import barGraph1 from '../assets/images/Bar Chart 1.svg'
import barGraph2 from '../assets/images/Bar Chart 2.svg'
import { postCall } from '../components/CommonApiCall';

function DynamicSubscriptionDashboard() {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [refresLoading, setRefresLoading] = useState(false)

    const getData = async () => {
        setLoading(true)
        const successCb = (response) => {
            setLoading(false)
            // setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     total: response?.data?.[0]?.totalResults,
            // }));
            let data = response?.data?.data??{}
            console.log("data ===> ",data)
            setData(data)
        }
        const failureCb = (response) => {
            console.log("failure ===> ",response)
            setLoading(false)
            setData([])
            
        }
        postCall({}, "admin/dynaminSubscription/dynamicDashboard", successCb, failureCb)
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className='Maincard p-3'>
            <Form form={form} >
                <Spin spinning={loading}>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h5 className='fw-bold mt-2'>Dashboard</h5>
                        </Col>
                    </Row>
                    <Row>
                        
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className="mt-4 crads">
                                <Row gutter={[30, 30]} className='d-flex justify-content-around'>
                                    <Col xs={24} sm={24} md={12} lg={8}>
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Subscribed Users</Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">{data?.subscribed_users??0}
                                                    </Typography>
                                                </div>
                                                <img src={barGraph1} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8}>
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Expired Users  </Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">{data?.expired_users??0}</Typography>
                                                </div>
                                                <img src={barGraph2} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8}>
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Renewed Users </Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">{data?.renewed_users??0}</Typography>
                                                </div>
                                                <img src={barchart} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className="mt-4 crads">
                                <Row gutter={[30, 30]} className='d-flex justify-content-start'>
                                    <Col xs={24} sm={24} md={12} lg={8}>
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Upgraded Users</Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">{data?.upgraded_users??0}
                                                    </Typography>
                                                </div>
                                                <img src={barGraph1} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>
                                    

                                    <Col xs={24} sm={24} md={12} lg={8}>
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Gross Earnings</Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">${parseInt(data?.gross_earning??0)}
                                                    </Typography>
                                                </div>
                                                <img src={barGraph1} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8}>
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Unsubscribed Users</Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">{parseInt(data?.unsubscribed_users??0)}
                                                    </Typography>
                                                </div>
                                                <img src={barGraph1} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </div>
                        </Col>
                    </Row>

                  


                    
      
                   

                </Spin>
            </Form>
        </div>
    )
}

export default DynamicSubscriptionDashboard