import React, { useEffect, useState } from 'react'
import { Spin, message } from 'antd';
import Editor from '../components/Editor';
import { getAppData, postCall } from '../components/CommonApiCall';


function PrivacyPolicy() {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')


  useEffect(() => {
    getData()
  }, [])

  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  }

  //  to fetch the data
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      setId(response?.data?.data?._id)
      const htmlData = htmlDecode(response?.data?.data?.policy)
      setText(htmlData)
    }
    const failureCb = (response) => {
      setLoading(false)
    }
    getAppData("privacy_policy", successCb, failureCb);
  }

  //**** Update The Data ***//
  const handleChange = (value) => {
    setLoading(true)
    setText(value);
    let data = {
      _id: id,
      type: "policy",
      content: value,
    }
    let url = '/settings/update_about_app'
    const successCb = (res) => {
      setLoading(false)
      getData()
      message.success("Data Updated Successfully")
    }
    const failureCb = (res) => {
      setLoading(false)
    };
    postCall(data, url, successCb, failureCb)
  };

  return (
    <div className='Maincard pt-3 p-5'>
      <h3>Privacy Policies</h3>
      <Editor text={text} handleUpdate={handleChange} />
      {loading && <Spin size="large" className='loader' />}
    </div>
  )
}

export default PrivacyPolicy