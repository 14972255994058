import thunk from "redux-thunk";
import { createStore, applyMiddleware,combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from 'redux-logger';
import root from "./redux/reducer.js";

const initialState = {};
const middleware = [thunk];

/* IN CASE OF MULTIPLE "REDUCER" */
// const rootReducer = combineReducers({
//     key1: root,
//     key2: ANOTHER_REDUCER_HERE

//   });

if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
  }

const store = createStore(  root,  initialState,  composeWithDevTools(applyMiddleware(...middleware)));

export default store;