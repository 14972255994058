import AppUtils from "../Utils/AppUtils";
import { baseUrl } from "../constants/const";
import { apiCall } from "./ApiCall";

// ***** Common function postCall for updation , delete, status change *******//
export async function postCall(data, url, successCb, failureCb) {
    let config = {
        method: "post",
        headers: AppUtils.getHeader(),
        url: `${baseUrl}${url}`,
        data,
    };

    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err.message);
    }
    if (response?.status == 200 || response?.status == 201) {
        return successCb(response)
    } else return failureCb(response)
}

export async function postCallResetPassword(data, url, successCb, failureCb) {
    let token = localStorage.getItem('access_token')
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${url}?token=${token}`,
        data,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err.message);
    }
    if (response?.status == 200) {
        return successCb(response)
    } else return failureCb(response)
}

//** Called to fetch the users data record **//
export const getDataCall = async (page, limit, keyword, get_url = null, successCb, failureCb, type = '') => {
    let config = {
        method: "get",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${get_url}?page=${page}&limit=${limit}&keyword=${keyword}`,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err)
    }
    if (response?.status == 200) {
        successCb(response)
    } else {
        failureCb(response)
    }
}
export const getDataCallContact = async (get_url, successCb, failureCb, test) => {
    let config = {
        method: "get",
        headers: AppUtils.getHeader(),
        url: `${baseUrl}${get_url}`,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err)
    }
    if (response?.status == 200) {
        successCb(response)
    } else {
        failureCb(response)
    }
}
export const getDataCallResult = async (page, limit, keyword, get_url = null, successCb, failureCb, type = '') => {
    let config = {
        method: "get",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${get_url}?page=${page}&limit=${limit}&sortBy=createdAt:desc&search=${keyword}`,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err)
    }
    if (response?.status == 200) {
        successCb(response)
    } else {
        failureCb(response)
    }
}
export const getDataReports = async (page, limit, keyword, get_url = null, successCb, failureCb, type = 'user') => {
    let config = {
        method: "get",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${get_url}?page=${page}&limit=${limit}&reportType=${type}&populate=reportedTo,stream,commentBy,reportedBy`,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err)
    }
    if (response?.status == 200) {
        successCb(response)
    } else {
        failureCb(response)
    }
}
export const getDataDashboard = async (page, limit, get_url = null, keyword,is_export=0, successCb, failureCb) => {
    let url;
    if (keyword) url = `${baseUrl}${get_url}?page=${page}&limit=${limit}&sortBy=createdAt:desc&search=${keyword}`
    
    else url = `${baseUrl}${get_url}?page=${page}&limit=${limit}&sortBy=createdAt:desc`
    if(is_export) {
        url = url+"&is_export=1"
    }
    let config = {
        method: "get",
        headers: { 'Content-Type': 'application/json' },
        url: url,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err)
    }
    if (response?.status == 200) {
        successCb(response)
    } else {
        failureCb(response)
    }
}
//  Method to Get Data for terms & condition, about us , privacy policy
export const getAppData = async (type, successCb, failureCb) => {
    let config = {
        method: "get",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/settings/get_about_app`,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err)
    }
    if (response?.status == 200) {
        console.log("success", response)
        successCb(response)
    } else {
        failureCb(response)
    }
}

export const getPromoterData = async (page, limit, get_url, keyword, successCb, failureCb) => {
    let config = {
        method: "get",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${get_url}`,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err)
    }
    if (response?.status == 200) {
        successCb(response)
    } else {
        failureCb(response)
    }
}
export const postPromoterData = async (data, page, limit, get_url, keyword, successCb, failureCb) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${get_url}`,
        data
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err)
    }
    if (response?.status == 200) {
        successCb(response)
    } else {
        failureCb(response)
    }
}

// delete api call
export async function deleteCall(url, id, successCb, failureCb) {
    let config = {
        method: "delete",
        headers: AppUtils.getHeader(),
        url: `${baseUrl}${url}${id}`,
    };

    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err.message);
    }
    if (response?.status == 204 || response?.status == 200) {
        return successCb(response)
    } else return failureCb(response)
}

// method call for image saving using formdata
export const postAddCategory = async (data, get_url, successCb, failureCb) => {
    let formData = new FormData();
    formData.append("file", data?.file);
    console.log("api data", data, formData)
    //   return false;
    let config = {
        method: "post",
        headers: { 'Content-Type': 'multipart/form-data', },
        url: `${baseUrl}${get_url}`,
        data: formData,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err)
    }
    if (response?.status == 200) {
        successCb(response)
    } else {
        failureCb(response)
    }
}

export const postCallPatch = async (data, get_url, successCb, failureCb) => {
    let config = {
        method: "patch",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${get_url}`,
        data
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err)
    }
    if (response?.status == 200) {
        successCb(response)
    } else {
        failureCb(response)
    }
}

export const updateCatgoryStatusForStream = async (get_url = null,data, successCb, failureCb) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${get_url}`,
        data
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err)
    }
    if (response?.status == 200) {
        successCb(response)
    } else {
        failureCb(response)
    }
}