import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const StackedAreaChart = ({ graphData, type,dropDwnValue }) => {
  const [categories, setCategories] = useState([])
  const [values,setValues] = useState([])

  const createGraphData = () => {
    let empArrForDate = []
    let empArrForValue = []
    graphData?.forEach((ele) => {
      empArrForDate.push(ele?._id)
      empArrForValue.push(ele?.totalEarning)
    })
    setCategories(empArrForDate, ...categories)
    setValues(empArrForValue, ...categories)
    
  }
  useEffect(() => {
    if (type === "tipAnalytics") {
      createGraphData()
    }
  }, [graphData])

  const options = {
    chart: {
      type: 'areaspline',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories:categories,
    },
    yAxis: {
      title: {
        text: '',
      },
      max: Math.max(...values) , // Replace 'highestValue' with your actual highest value
      tickInterval: 20, 
      // categories: [values]
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#BDA7EC',
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: '#BDA7EC',
        },
      },
    },
    series: [
      {
        name: dropDwnValue?.toUpperCase(),
        data:values,
        color: '#6240C9',
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default StackedAreaChart;
