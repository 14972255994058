import axios from "axios";

// method to call APIS and return the promise
export const apiCall = (config) => {
    return new Promise((resolve, reject) => {
            axios({
                method: config?.method,
                url: config?.url,
                headers: config?.headers,
                data: config?.data || {},
                timeout: 60000,
                Authentication: config?.Authentication
            }).then(async (response) => {
                let responseData = {
                    status:response?.status??100,
                    data:response?.data
                }
                resolve(responseData)
            }).catch(async (error) => {
                let response = error?.response;
                let responseData = {
                    status:response?.status??100,
                    data:response?.data
                }
                resolve(responseData)
                if (response?.status === 401) { }
                // reject(error);
            });
        });
};

// new api call 

// export const apiCallWithRedux = (config, dispatch) => {
//     return new Promise((resolve, reject) => {
//         axios({
//             method: config?.method,
//             url: config?.url,
//             headers: config?.headers,
//             data: config?.data || {},
//             timeout: 70000,
//         })
//             .then(async (response) => {

//                 resolve(response)
//             })
//             .catch(async (error) => {
//                 let response = error?.response;
//                 if (response?.status === 400) {
//                     resolve(response)
//                 }
//                 if (response?.status === 401) {
//                     // refreshToken()
//                 }
//                 reject(error);
//             });
//     });
// };