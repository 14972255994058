import { Button, Col, Form, Input, Row, Spin, Switch, Typography } from "antd"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { mainAction } from "../redux/action/mainAction"
import { useForm } from "antd/es/form/Form"
import { toast } from "react-toastify"

const Advertisement = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [updateBtnLoading, setUpdateBtnLoading] = useState(false)
    const [ObjctId, setObjctId] = useState({})
    const [loading, setLoading] = useState(false)
    const [freeDisabled, setFreeDisabled] = useState(false)
    const [paidDisabled, setPaidDisabled] = useState(false)
    const [freeUsers, setFreeUsers] = useState({})
    const [paidUsers, setPaidUsers] = useState({})
    const getData = () => {
        setLoading(true)
        const successCb = (res) => {
            let freeUsers = res?.free_user
            let paidUsers = res?.paid_user
            setFreeUsers(freeUsers)
            setPaidUsers(paidUsers)
            setLoading(false)
            setFreeDisabled(freeUsers?.advert_status === 0 ? true : false)
            setPaidDisabled(paidUsers?.advert_status === 0 ? true : false)
            form.setFieldsValue({
                Free_advert_status: freeUsers?.advert_status === 1 ? true : false,
                Free_workoutroom_listing: freeUsers?.workoutroom_listing === 1 ? true : false,
                Free_feed_page: freeUsers?.feed_page === 1 ? true : false,
                Free_video_listing: freeUsers?.video_listing === 1 ? true : false,
                Free_popup_time_in_min: freeUsers?.popup_time_in_min,

                Paid_advert_status: paidUsers?.advert_status === 1 ? true : false,
                Paid_workoutroom_listing: paidUsers?.workoutroom_listing === 1 ? true : false,
                Paid_feed_page: paidUsers?.feed_page === 1 ? true : false,
                Paid_video_listing: paidUsers?.video_listing === 1 ? true : false,
                Paid_popup_time_in_min: paidUsers?.popup_time_in_min,
            })
            setObjctId(res?._id)
        }
        const failureCb = (res) => {
            setLoading(false)
            console.log("ressErrr", res);
        }
        dispatch(mainAction(`admin/advert/get_advert`, 'get', '', successCb, failureCb, true, false, ''))
    }

    useEffect(() => {
        getData()
    }, [])

    const setValues = (type) => {
        if(type=="free"){
            form.setFieldsValue({
                Free_workoutroom_listing: freeUsers?.workoutroom_listing === 1 ? true : false,
                Free_feed_page: freeUsers?.feed_page === 1 ? true : false,
                Free_video_listing: freeUsers?.video_listing === 1 ? true : false,
            })
        }else{

            form.setFieldsValue({
                Paid_workoutroom_listing: paidUsers?.workoutroom_listing === 1 ? true : false,
                Paid_feed_page: paidUsers?.feed_page === 1 ? true : false,
                Paid_video_listing: paidUsers?.video_listing === 1 ? true : false,
            })
        }
    }

    const googleAddDisble = (value, type) => {
        if (type == "free") {
            if (!value) {
                form.setFieldsValue({
                    Free_advert_status: false,
                    Free_workoutroom_listing: false,
                    Free_feed_page: false,
                    Free_video_listing: false,
                })
                setFreeDisabled(true)
            } else {
                setFreeDisabled(false)
                setValues("free")
            }
        } else {
            if (!value) {
                form.setFieldsValue({
                    Paid_advert_status: false,
                    Paid_workoutroom_listing: false,
                    Paid_feed_page: false,
                    Paid_video_listing: false,
                })
                setPaidDisabled(true)
            } else {
                setPaidDisabled(false)
                setValues("paid")
            }
        }
    }

    const updateFunc = (values) => {
        setUpdateBtnLoading(true)
        let free_user = {
            advert_status: values?.Free_advert_status ? 1 : '0',
            workoutroom_listing: values?.Free_workoutroom_listing ? 1 : '0',
            feed_page: values?.Free_feed_page ? 1 : '0',
            video_listing: values?.Free_video_listing ? 1 : '0',
            popup_time_in_min: values?.Free_popup_time_in_min ? values?.Free_popup_time_in_min : '0',
        }
        let paid_user = {
            advert_status: values?.Paid_advert_status ? 1 : '0',
            workoutroom_listing: values?.Paid_workoutroom_listing ? 1 : '0',
            feed_page: values?.Paid_feed_page ? 1 : '0',
            video_listing: values?.Paid_video_listing ? 1 : "0",
            popup_time_in_min: values?.Paid_popup_time_in_min ? values?.Paid_popup_time_in_min : '0',
        }

        let payload = {
            _id: ObjctId,
            free_user: free_user,
            paid_user: paid_user
        }

        const successCb = (res) => {
            setUpdateBtnLoading(false)
            getData()
        }
        const failureCb = (res) => {
            setUpdateBtnLoading(false)
        }
        dispatch(mainAction(`admin/advert/update_advert`, 'post', payload, successCb, failureCb, true, false, ''))

    }
    return (
        <Spin spinning={loading} className="">
            <h5 className='fw-bold'>Advertisement Setting</h5>
            <div className="mt-4">
                <Form onFinish={updateFunc} form={form} initialValues={{ remember: true, }}>
                    <Row gutter={[30, 30]}>
                        {/* free users */}
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <h6 className="fw-bold">Free User</h6>
                            <div className="advertiseMainDiv p-3 mt-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6>Enable Google Ads</h6>
                                    <Form.Item name="Free_advert_status" valuePropName="checked">
                                        <Switch onChange={(e) => googleAddDisble(e, "free")} />
                                    </Form.Item>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6>Ads In Workoutroom Listing</h6>
                                    <Form.Item name="Free_workoutroom_listing" valuePropName="checked">
                                        <Switch disabled={freeDisabled} />
                                    </Form.Item>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6>Ads In Feed Pages</h6>
                                    <Form.Item name="Free_feed_page" valuePropName="checked">
                                        <Switch disabled={freeDisabled} />
                                    </Form.Item>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6>Ads In Video Listing</h6>
                                    <Form.Item name="Free_video_listing" valuePropName="checked">
                                        <Switch disabled={freeDisabled} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="advertiseMainDiv adsInputDiv p-3 mt-4">
                                <div className="">
                                    <p className="mt-3" style={{ fontSize: '1rem', paddingLeft: 3 }}>Select Time</p>
                                </div>
                                <div className="settingInput subscrption text-end mt-4">
                                    <Form.Item name="Free_popup_time_in_min"
                                    >
                                        <Input suffix={<span style={{ color: 'grey' }}>minutes</span>} type="number" className="" onKeyPress={(e) => {
                                            if (e.key === '-' || e.key === 'e') {
                                                e.preventDefault(); // Prevent the input of '-' and 'e'
                                            }
                                        }} />
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                        {/* paid users */}
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <h6 className="fw-bold">Paid User</h6>
                            <div className="advertiseMainDiv p-3 mt-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6>Enable Google Ads</h6>
                                    <Form.Item name="Paid_advert_status" valuePropName="checked">
                                        <Switch onChange={(e) => googleAddDisble(e, "paid")} />
                                    </Form.Item>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6>Ads In Workoutroom Listing</h6>
                                    <Form.Item name="Paid_workoutroom_listing" valuePropName="checked">
                                        <Switch disabled={paidDisabled} />
                                    </Form.Item>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6>Ads In Feed Pages</h6>
                                    <Form.Item name="Paid_feed_page" valuePropName="checked">
                                        <Switch disabled={paidDisabled} />
                                    </Form.Item>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6>Ads In Video Listing</h6>
                                    <Form.Item name="Paid_video_listing" valuePropName="checked">
                                        <Switch disabled={paidDisabled} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="advertiseMainDiv adsInputDiv p-3 mt-4">
                                <div className="">
                                    <p className="mt-3" style={{ fontSize: '1rem', paddingLeft: 3 }}>Select Time</p>
                                </div>
                                <div className="settingInput subscrption text-end mt-4">
                                    <Form.Item name="Paid_popup_time_in_min">
                                        <Input suffix={<span style={{ color: 'grey' }}>minutes</span>} type="number" className="" onKeyPress={(e) => {
                                            if (e.key === '-' || e.key === 'e') {
                                                e.preventDefault(); // Prevent the input of '-' and 'e'
                                            }
                                        }} />
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="">
                        <Button htmlType="submit" loading={updateBtnLoading} type="ghost" className="purpleButton settingUpdateBtn mt-5">Update</Button>
                    </div>
                </Form>
            </div>
        </Spin>
    )
}
export default Advertisement