import React from 'react'

function App_Customization() {
  return (
    <div className='Maincard p-3'>
        <h4 className='fw-bold'>App Customization</h4>
    </div>
  )
}

export default App_Customization