import React, { useEffect, useState } from 'react'
import { Spin, message } from 'antd';
import Editor from '../components/Editor';
import { getAppData, postCall } from '../components/CommonApiCall';


function Aknowledgement() {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false)
    const [id, setId] = useState('')

  
    useEffect(() => {
      getData()
    }, [])
  
    function htmlDecode(input) {
      var doc = new DOMParser().parseFromString(input, 'text/html');
      return doc.documentElement.textContent;
    }

    //  to fetch the data
    const getData = async () => {
    //   return false;
      setLoading(true)
      const successCb = (response) => {
        setLoading(false)
        setId(response?.data?.data?._id)  
        const htmlData = htmlDecode(response?.data?.data?.acknowledgements)
        setText(htmlData)
      }
      const failureCb = (response) => {
        setLoading(false)
      }
    //   return false;
      getAppData("aknowledgements", successCb, failureCb);
    }
  
    //**** Update The Data ***//
    const handleChange = (value) => {
      setLoading(true)
      setText(value);
      let data = {
        _id: id,
        type: "ack",
        content: value,
      }
      let url = '/settings/update_about_app'
      const successCb = (res) => {
        setLoading(false)
        getData()
        message.success("Data Updated Successfully")
      }
      const failureCb = (res) => {
        setLoading(false)
      }
      // return false;
      postCall(data, url, successCb, failureCb)
    };
  
    return (
      <div className='Maincard pt-3 p-5'>
        <h3>Aknowledgements</h3>
        <Editor text={text} handleUpdate={handleChange} />
        {loading && <Spin size="large" className='loader' />}
      </div>
    )
}

export default Aknowledgement