import { Col, Row, Space, Table, Typography, Spin, Button } from 'antd';
import React, { useState, useEffect } from 'react'
import Search from 'antd/es/input/Search';
import { getPromoterData, postPromoterData } from '../components/CommonApiCall';
import moment from 'moment';

const dataSource = [
  {
    key: '1',
    promoter: "Steve Young",
    email: "steve@check.com",
    customers: 5,
    owed: "$1",
    paid: "$15",
    revenue: "$5",
    joined: "25 Oct 2023"
  },
  {
    key: '2',
    promoter: "Steve Young",
    email: "steve@check.com",
    customers: 5,
    owed: "$1",
    paid: "$15",
    revenue: "$5",
    joined: "25 Oct 2023"
  },
  {
    key: '3',
    promoter: "Steve Young",
    email: "steve@check.com",
    customers: 5,
    owed: "$1",
    paid: "$15",
    revenue: "$5",
    joined: "25 Oct 2023"
  },
  {
    key: '4',
    promoter: "Steve Young",
    email: "steve@check.com",
    customers: 5,
    owed: "$1",
    paid: "$15",
    revenue: "$5",
    joined: "25 Oct 2023"
  },
  {
    key: '5',
    promoter: "Steve Young",
    email: "steve@check.com",
    customers: 5,
    owed: "$1",
    paid: "$15",
    revenue: "$5",
    joined: "25 Oct 2023"
  },
  {
    key: '6',
    promoter: "Steve Young",
    email: "steve@check.com",
    customers: 5,
    owed: "$1",
    paid: "$15",
    revenue: "$5",
    joined: "25 Oct 2023"
  },
]

function Promoters() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: ['bottomCenter'],
    size: 'small'
  });

  useEffect(() => {
    getData();
  }, [pagination?.current, pagination?.pageSize, searchText])

  //** Called to fetch the users data record **//
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
   
      let data = response?.data?.data.map((item) => {
        let firstName = item?.profile?.first_name ?? "--";
        let lastName = item?.profile?.last_name ?? "";

        return ({
          _id: item?.id,
          key: item?.id,
          promoter: `${firstName} ${lastName}`,
          email: item?.email,
          owed: item?.earning_balance ?? "-",
          paid: item?.paid_balance ?? "-",
          revenue: item?.current_balance ?? "-",
          joined: moment(item?.created_at).format("YYYY-MM-DD"),
        })
      })
      setUserData(data);
    }
    const failureCb = (response) => {
      setLoading(false)
      setUserData('')
      setPagination({
        current: 1,
        total: 0,
        pageSize: 5,
      });
    }
    getPromoterData(pagination?.current, pagination?.pageSize, "promoter", searchText, successCb, failureCb);
  }


  // *** Page change ******//
  const handlePageChange = (page, pageSize) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page,
      pageSize: pageSize,
    }));
  };

  //*** to handle the Search Value ***//
  const handleSearch = (value) => {
    setSearchText(value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));
  };


  // columns for the table //
  const columns = [
    {
      title: 'Promoter Name',
      dataIndex: 'promoter',
      key: 'promoter',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    // {
    //   title: 'Customers',
    //   dataIndex: 'customers',
    //   key: 'customers',
    // },
    {
      title: 'Owed',
      dataIndex: 'owed',
      key: 'owed',
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      key: 'paid',
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
    },
    {
      title: 'Joined',
      dataIndex: 'joined',
      key: 'joined',
    },
  ];

  // handler for selected rows // 
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <div>
      {loading && <Spin size="large" className='loader' />}
        <div className='d-flex justify-content-between Maincard p-3 align-items-center mb-3'>
          <h4 className='fw-bold m-0'>Promoters</h4>
          <Button className='viewButton' onClick={() => window.open("https://workoutpartner.firstpromoter.com/users/sign_in", '_blank')}  >First Promoter</Button>
        </div>
      <Table
        dataSource={userData}
        columns={columns}
        scroll={{ x: "250px" }}
        pagination={{
          ...pagination,
          onChange: handlePageChange,
        }} />
    </div>
  )
}

export default Promoters