import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mainAction } from "../redux/action/mainAction";
import { toast } from "react-toastify";

const Settings = () => {
    const dispatch = useDispatch()
    const [tipValue, setTipValue] = useState()
    const [subscriptionValue, setSubscriptionValue] = useState()
    const [updateBtnLoading, setUpdateBtnLoading] = useState(false)
    const [toastFalse, setToastFalse] = useState(false)
    const [apiValues,setApiValues] = useState({
        tip:'',
        sub_watch:""
    })
    const [id, setId] = useState('')
    const tipValueHandleChange = (e) => {
        let value = e.target.value
        // setTipValue(value)
        if (value < 0 || value === '0') {
            e.preventDefault();
        } else {
            setTipValue(value);
        }
    }
    const subScrptionOnChange = (e) => {
        let value = e.target.value
        if (value < 0 || value === '0') {
            e.preventDefault();
        } else {
            setSubscriptionValue(value);
        }
    }
    const getTipValue = () => {
        const successCb = (res) => {
            setTipValue(res?.data?.tip_withdrawal_value)
            setSubscriptionValue(res?.data?.free_watch_hours)
            setId(res?.data?._id)
            setApiValues({
                tip:res?.data?.tip_withdrawal_value,
                sub_watch:res?.data?.free_watch_hours
            })
        }
        const failureCb = (res) => {
            console.log("tipValue,fail", res);
        }
        dispatch(mainAction(`settings/get_about_app`, 'get', '', successCb, failureCb, true, false, ''))
    }

    const updateFunc = () => {
        if (tipValue && subscriptionValue) {
            if(apiValues.sub_watch!=subscriptionValue||apiValues?.tip!=tipValue){
                let monthHours = 24 * 31
                if (subscriptionValue <= monthHours) {
                    setUpdateBtnLoading(true)
                    let payload = {
                        type: "setting",
                        tip_withdrawal_value: tipValue?.toString(),
                        free_watch_hours: subscriptionValue?.toString(),
                        content: "--",
                        _id: id,
                    }
                    const successCb = (res) => {
                        setToastFalse(false)
                        setUpdateBtnLoading(false)
                        getTipValue()
                        toast.success(res?.message)
                    }
                    const failureCb = (res) => {
                        setUpdateBtnLoading(false)
                        console.log("tipValue,fail", res);
                    }
                    dispatch(mainAction(`settings/update_about_app`, 'post', payload, successCb, failureCb, true, false, ''))
                } else {
                    setToastFalse(true)
                    if (!toastFalse) {
                        toast.error('Hours exceed')
                    }
                    setTimeout(() => {
                        if (!toastFalse) {
                            setToastFalse(false)
                        }
                    }, 5500);
                }
            }
        } else {
            toast.error("Please enter valid value")
        }
    }
    useEffect(() => {
        getTipValue()
    }, [])
    console.log("tipValue", tipValue);

    return (
        <div className="">
            <h5 className='fw-bold my-2'>Setting</h5>
            <div className="mt-4">
                {/* tip value */}
                {/* <div className="outBoxSettingInput px-2">
                    <div className="">
                        <p className="mt-3" style={{ fontSize: '1rem', paddingLeft: 3 }}>1 Tip Value</p>
                    </div>
                    <div className="settingInput text-end">
                        <Input prefix={`$`} type="number" onChange={(e) => tipValueHandleChange(e)} value={tipValue} className="" />
                    </div>
                </div> */}
                {/* subscription */}
                <div className="outBoxSettingInput px-2 mt-4">
                    <div className="">
                        <p className="mt-3" style={{ fontSize: '1rem', paddingLeft: 3 }}>Free Subscription Watching Hours/month</p>
                    </div>
                    <div className="settingInput subscrption text-end">
                        <Input suffix={`Hours`} type="number" onChange={(e) => subScrptionOnChange(e)} value={subscriptionValue} className="" />
                    </div>
                </div>

                <Button loading={updateBtnLoading} type="ghost" className="purpleButton settingUpdateBtn mt-5" onClick={() => updateFunc()}>Update</Button>
            </div>
        </div>
    )
}

export default Settings