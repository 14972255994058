import { Avatar, Button, Checkbox, Col, Modal, Row, Select, Spin, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ReloadOutlined, } from '@ant-design/icons';
import barGraph1 from "../assets/images/Bar Chart.svg"
import barGraph2 from "../assets/images/Bar Chart 1.svg"
import { mainAction } from "../redux/action/mainAction";
import { useDispatch } from "react-redux";
import { fileUrl } from "../constants/const";
import { toast } from "react-toastify";
import moment from "moment";
const PayoutReq = () => {
    const dispatch = useDispatch()
    const [requestType, setRequestType] = useState('requested')
    const [tableData, setTableData] = useState([])
    const [reqAmountData, setReqAmountData] = useState({})
    const [loading, setLoading] = useState(false)
    const [approveModal, setApproveModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState({})
    const [refresLoading, setRefresLoading] = useState(false)
    const [approveLoading, setApproveLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
        tip_value: 1
    })
    const getReqstDetail = (page, limit) => {
        setLoading(true)
        const successCb = (res) => {
            setLoading(false)
            setTableData(res?.data)
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: res?.other?.total_entries,
                tip_value: res?.other?.tip_value ?? 1
            }));
        }
        const failureCb = (res) => {
            setLoading(false)
        }
        dispatch(mainAction(`admin/tip/get_payout_list?page=${page}&limit=${limit}&type=${requestType}`, 'get', '', successCb, failureCb, true, false, ''))
    }

    useEffect(() => {
        getReqstDetail(pagination?.current, pagination?.pageSize)
    }, [requestType])
    useEffect(() => {
        getAmounts()
    }, [])

    const getAmounts = () => {
        setRefresLoading(true)
        const successCb = (res) => {
            setReqAmountData(res?.data)
            setRefresLoading(false)
        }
        const failureCb = (res) => {
            setRefresLoading(false)
        }
        dispatch(mainAction(`admin/tip/get_payout_summary`, 'get', '', successCb, failureCb, true, false, ''))
    }

    const handlePageChange = (page, pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: page,
            pageSize: pageSize,
        }));
        getReqstDetail(page, pageSize)
    };

    const columnsCurrentReq = [
        {
            title: 'Requested By',
            dataIndex: 'name',
            render: (value, object, indx) => {
                return (
                    <>

                        <Typography className="fw-bold">{object?.requestedByUser?.firstName ?
                            <div>
                                {
                                    object?.requestedByUser?.profilePic ?
                                        <Avatar src={`${fileUrl}${object?.requestedByUser?.profilePic}`} />
                                        :
                                        <Avatar size={30} />
                                }
                                <span className="px-2">
                                    {
                                        `${object?.requestedByUser?.firstName} ${object?.requestedByUser?.lastName}`
                                    }
                                </span>
                            </div>
                            : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.requestedByUser?.email ? object?.requestedByUser?.email : "---"}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Amount',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{`$${object?.tip}` ?? "---"}</Typography>
                    </>
                )
            }
        },
        // {
        //     title: 'Amount',
        //     dataIndex: 'paid',
        //     render: (value, object, indx) => {
        //         return (
        //             <>
        //                 <Typography className="fw-bold">${object?.tip ? `${object?.tip * pagination?.tip_value}` : '---'}</Typography>
        //             </>
        //         )
        //     }
        // },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            render: (value, object, indx) => {
                return (
                    <>
                        <Button className='viewButton' onClick={() => { setApproveModal(true); setSelectedUser(object) }}>Approve</Button>
                    </>)
            },
        },
    ]
    const columnsCurrentApprove = [
        {
            title: 'Requested By',
            dataIndex: 'name',
            render: (value, object, indx) => {
                return (
                    <>

                        <Typography className="fw-bold">{object?.requestedByUser?.firstName ?
                            <div>
                                {
                                    object?.requestedByUser?.profilePic ?
                                        <Avatar src={`${fileUrl}${object?.requestedByUser?.profilePic}`} />
                                        :
                                        <Avatar size={30} />
                                }
                                <span className="px-2">
                                    {
                                        `${object?.requestedByUser?.firstName} ${object?.requestedByUser?.lastName}`
                                    }
                                </span>
                            </div>
                            : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.requestedByUser?.email ? object?.requestedByUser?.email : "---"}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Amount',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">${object?.tip ?? "---"}</Typography>
                    </>
                )
            }
        },
        // {
        //     title: 'Paid Amount',
        //     dataIndex: 'paid',
        //     render: (value, object, indx) => {
        //         return (
        //             <>
        //                 <Typography className="fw-bold">${object?.amount_paid ? object?.amount_paid  : '---'}</Typography>
        //             </>
        //         )
        //     }
        // },
        {
            title: 'Paid At',
            dataIndex: 'paid',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold"> {object?.paidAt ? moment(object?.paidAt).format('MM-DD-YYYY') : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Status',
            render: (value, object, indx) => {
                return (
                    <>
                        <Button style={{backgroundColor:"#43dd80",color:'black'}} className='viewButton'>Approved</Button>
                    </>)
            },
        },
    ]

    const handleApprove = () => {
        if (selectedUser?.requestedByUser?.paypal_email != '') {
            setApproveLoading(true)
            const successCb = (res) => {
                setApproveLoading(false)
                setApproveModal(false)
                if (!res?.status) {
                    toast.error(res?.error?.message)
                }
                getReqstDetail(pagination?.current, pagination?.pageSize)
                toast.success(res?.message)
            }
            const failureCb = (res) => {
                setApproveModal(false)
                setApproveLoading(false)
                toast.error(res?.message)
            }
            dispatch(mainAction(`admin/tip/approve_payout_request?request_id=${selectedUser?._id}&total_tips=${selectedUser?.tip}&paypal_email=${selectedUser?.requestedByUser?.paypal_email}&amount=${selectedUser?.tip}`, 'get', '', successCb, failureCb, true, false, ''))
        } else {
            toast.error("Paypalemail Not Found!")
            setApproveModal(false)
        }
    }

    return (
        <>
            <div className="">
                <Spin spinning={loading}>
                    <h5 className='fw-bold my-2'>Payout Requests</h5>
                    <div className="mt-4 crads">
                        <Row gutter={[30, 30]}>
                            <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                                <div className="Maincard p-3" style={{ cursor: "pointer" }} >
                                    <div className="d-flex justify-content-between">
                                        <Typography className="total_paidText">Total Paid Amount</Typography>
                                        <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} onClick={() => getAmounts()}>
                                            <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                            <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                        </Typography>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        <div className="amountPrt">
                                            <Typography className="dollerAmount">${reqAmountData?.paidAmount}
                                            </Typography>
                                        </div>
                                        <img src={barGraph1} alt="bargraph_img" />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                <div className="Maincard p-3" style={{ cursor: "pointer" }}>
                                    <div className="d-flex justify-content-between">
                                        <Typography className="total_paidText">Total Unpaid Amount</Typography>
                                        <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} onClick={() => getAmounts()}>
                                            <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                            <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                        </Typography>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        <div className="amountPrt">
                                            <Typography className="dollerAmount">${reqAmountData?.unpaidAmount?? 0}</Typography>
                                        </div>
                                        <img src={barGraph2} alt="bargraph_img" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/* table */}
                    <div className="tablePart mt-5">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="">
                                <h5 className='fw-bold my-2'>{requestType == "requested" ? "Current Requests" : 'Approved Requests'}</h5>
                            </div>
                            <div className="dropdwnDiv text-end">
                                <Select
                                    onChange={(value) => setRequestType(value)}
                                    style={{ borderRadius: 10, }}
                                    defaultValue={'requested'}
                                    options={[
                                        {
                                            label: "Current Request",
                                            value: 'requested'
                                        },
                                        {
                                            label: "Approved Request",
                                            value: 'approved'
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="mt-3">
                            {requestType == "requested" ?
                                <Table
                                    columns={columnsCurrentReq}
                                    dataSource={tableData}
                                    scroll={{ x: "250px" }}
                                    pagination={{
                                        ...pagination,
                                        onChange: handlePageChange,
                                    }}
                                />
                                :
                                <Table
                                    columns={columnsCurrentApprove}
                                    dataSource={tableData}
                                    scroll={{ x: "250px" }}
                                    pagination={{
                                        ...pagination,
                                        onChange: handlePageChange,
                                    }}
                                />
                            }
                        </div>
                    </div>
                </Spin>
            </div >


            {/* approve modal */}
            <Modal
                centered
                open={approveModal}
                onCancel={() => setApproveModal(false)}
                footer={null}
                width={350}
            >
                <p className="text-danger">Are you sure you want to Approve this Payment?</p>
                <p>
                    Requested By : {`${selectedUser?.requestedByUser
                        ?.firstName} ${selectedUser?.requestedByUser
                            ?.lastName}`} <br />
                        Amount : ${selectedUser?.tip} <br />
                    {/* Total Amount : ${selectedUser?.tip * pagination?.tip_value}<br /> */}
                </p><br />
                <div className="mt-2">
                    <Button loading={approveLoading} type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={() => handleApprove()}>Approve</Button>
                    <Button danger type="primary" className='w-48 ' onClick={() => { setApproveModal(false) }}>Cancel</Button>
                </div>
            </Modal>
        </>
    )
}

export default PayoutReq;