const AppUtils = {
   getHeader:() =>{
    let header = {
        Authorization :`Bearer ${localStorage.getItem("access_token")}`,
        "x-csrf-token" : `${localStorage.getItem("csrf_token")}`
    }
    return header
   },
   showMessage: (key, message) => {
    if (typeof message === 'undefined') {
      console.log(key)
    }
    else {
      console.log(key, message)
    }

  },
}

export default AppUtils