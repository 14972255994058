import React from 'react'

function Web_Customization() {
    return (
        <div className='Maincard p-3'>
            <h4 className='fw-bold'>Web Customization</h4>
        </div>
    )
}

export default Web_Customization