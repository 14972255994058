import { Button, Input, Radio, Typography, message } from 'antd'
import React, { useState } from 'react'
import { postCall } from '../components/CommonApiCall';

function CustomNotification() {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [customNotification, setCustomNotification] = useState();
  const handleInputNotification = (e) => {
    setCustomNotification(e.target.value)
  }
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const handleSend = () => {
    if(!value){message.error("Select The User Type"); return false}
    if(!customNotification){message.error("Notification cann't be empty"); return false;}
    setLoading(true)
    let data = {
      msg : customNotification,
      type : value
    }
    //  return false;
    let url = '/notifications/sendCustomNotification'
    const successCb = (res) => {
      setLoading(false)
      message.success("Notification Sent Successfully")
      setValue('')
      setCustomNotification('')
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  };
  return (
    <div className='p-4 Maincard'>
      <Typography.Title level={4} className='mb-3 mt-0 fw-bold' >Custom Notification</Typography.Title>
      {/* <Typography.Title level={5}>Select User Type</Typography.Title> */}
      <div className='fw-bold mb-1'>Select User Type</div>
      <Radio.Group onChange={onChange} value={value} buttonPaddingInline={30} dotSize={20}>
        <Radio className="mt-2" value="viewer">Viewer</Radio>
        <Radio className="me-sm-3 mx-lg-3" value="host">Host</Radio>
        <Radio className="me-sm-3 mx-lg-3" value="coach">Coaches</Radio>
        <Radio className="" value="all">All Users</Radio>
      </Radio.Group>
      <div className='mt-3'><Input className='input_width' onChange={handleInputNotification} placeholder='Type your notification here' /></div>
      {/* <div className='mt-4 notiText'>Type your notification here</div> */}
      <div>
        <Button type="primary" htmlType="submit" className="button_common mt-5" onClick={handleSend}>
          Send
        </Button>
      </div>
    </div>
  )
}

export default CustomNotification