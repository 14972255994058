import React from "react";
import { useNavigate} from "react-router-dom";
import Login from '../screens/Login';


const ProtectedRoute = ({ children }) => {
    const navigate  = useNavigate()
    const token  = window.localStorage.getItem("access_token");
    if (!token) {
        navigate("/");
        return <Login />
    }
    else {
        return children;
    }
};

export default ProtectedRoute;