import { Button, Col, Form, Input, Row, Switch, Select, Collapse, Spin, message, Divider, Table, Modal, Tooltip, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import AppUtils from '../Utils/AppUtils';
import { getDataCall, postCall } from '../components/CommonApiCall';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
function DynamicSubscriptionNew() {
    const [form] = Form.useForm();
    const [monthlyForm] = Form.useForm();
    const [yearlyForm] = Form.useForm();
    const [updateFields, setUpdateFields] = useState([]);
    const [loading, setLoading] = useState(false)
    const [updateBtnLoading, setUpdateBtnLoading] = useState(false)
    const navigate = useNavigate();

    const [duration, setDuration] = useState([
        {
            label: "Monthly",
            value: 'monthly'
        },
        {
            label: "Yearly",
            value: 'yearly'
        },
    ])
    const [gracePeriod, setGracePeriod] = useState([
        {
            label: "Month",
            value: 'month'
        },
        {
            label: "Days",
            value: 'days'
        },
    ])
    const [selectedDuration, setSelectedDuration] = useState([])
    const [coupons_monthly, setCouponsMonthly] = useState([])
    const [coupons_yearly, setCouponsYearly] = useState([])
    const [monthlyModal, setMonthlyModal] = useState(false)
    const [yearlyModal, setYearlyModal] = useState(false)
    const [updateMonthly, setUpdateMonthly] = useState(false)
    const [updateYearly, setUpdateYearly] = useState(false)
    const [subscriptionFeatures, setSubscriptionFeatures] = useState([])

    const columns_monthly = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
        {
            title: 'Coupon Description',
            dataIndex: 'coupon_desc',
            key: 'coupon_desc',
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            align: "center",
            render: (_, record) => {

                return (
                    <>

                        <Tooltip title="Edit Coupon">
                            <>
                                <i class="bi bi-pencil-square me-2" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onEditMonthly(record)}></i>
                            </>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <>
                                <i class="bi bi-trash" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onDeleteMonthly(record?.sno)}></i>
                            </>
                        </Tooltip>
                    </>)
            },
        }
    ];

    const columns_yearly = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
        {
            title: 'Coupon Description',
            dataIndex: 'coupon_desc',
            key: 'coupon_desc',
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            align: "center",
            render: (_, record) => {

                return (
                    <>

                        <Tooltip title="Edit Coupon">
                            <>
                                <i class="bi bi-pencil-square me-2" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onEditYearly(record)}></i>
                            </>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <>
                                <i class="bi bi-trash" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onDeleteYearly(record?.sno)}></i>
                            </>
                        </Tooltip>
                    </>)
            },
        }
    ];

    // const [updateFields, setUpdateFields] = useState([
    //       {
    //           name: ["plan_name"],
    //           value: "",
    //       },
    //       {
    //           name: ["desc"],
    //           value: "",
    //       },
    //       {
    //           name: ["android_plan_id_monthly"],
    //           value: "",
    //       },
    //       {
    //           name: ["ios_plan_id_monthly"],
    //           value: "",
    //       },
    //       {
    //           name: ["grace_period_monthly"],
    //           value: ""
    //       }, 
    //       {
    //           name: ["grace_period_unit_monthly"],
    //           value: "",
    //       },
    //       {
    //           name: ["price_monthly"],
    //           value: "",
    //       },
    //       {
    //           name: ["android_plan_id_yearly"],
    //           value: "",
    //       },
    //       {
    //           name: ["ios_plan_id_yearly"],
    //           value: "",
    //       },
    //       {
    //           name: ["grace_period_yearly"],
    //           value: ""
    //       }, 
    //       {
    //           name: ["grace_period_unit_yearly"],
    //           value: "",
    //       },
    //       {
    //           name: ["price_yearly"],
    //           value: "",
    //       },
    // ]);


    const handleGracePeriod = (value) => {
        console.log(`selected ${value}`);
    };

    const updateFuncFree = (values) => {
        console.log("stringify values ", JSON.stringify(values))
        console.log("values ", values)
        let quantityValues = subscriptionFeatures.filter((ite) => ite.is_quantity)
        console.log("stringify values ", quantityValues)
        let isVerified = true
        let message = ''
        for(let i=0;i<quantityValues.length;i++)  {
            let it = quantityValues[i]
            if (it.enabled === 'true') {
                if (!it.is_unlimited && it.quantity === 0) {
                    isVerified = false
                    message = `${it.name} should not be empty`
                    break
                }
            }
        }
        if (!isVerified) {
            toast.error(message)
            return
        }
        createPlan(values)
    }

    const createPlan = async (data) => {
        const {
            plan_name, desc, android_plan_id_monthly, ios_plan_id_monthly, grace_period_monthly, grace_period_unit_monthly, price_monthly,
            android_plan_id_yearly, ios_plan_id_yearly, grace_period_yearly, grace_period_unit_yearly, price_yearly
        } = data

        let formData = {
            plan_name,
            desc,
            android_plan_id_monthly,
            ios_plan_id_monthly,
            grace_period_monthly,
            grace_period_unit_monthly,
            price_monthly,
            android_plan_id_yearly,
            ios_plan_id_yearly,
            grace_period_yearly,
            grace_period_unit_yearly,
            price_yearly,
            coupons_monthly,
            coupons_yearly,
            features: subscriptionFeatures
        }
        AppUtils.showMessage("formData ", JSON.stringify(formData))
        setLoading(true)
        const successCb = (response) => {
            AppUtils.showMessage("success response ", response)
            setLoading(false)
            message.success(response?.data?.message)
            navigate('/dynamic_subscription')
        }
        const failureCb = (response) => {
            AppUtils.showMessage("failure response ", response)
            setLoading(false)
            message.error(response?.data?.message ?? "Error creating plan")
        }

        await postCall(formData, "admin/dynaminSubscription", successCb, failureCb)
    }



    const onUpdateForm = () => {
        form.submit()

    }


    const onCancelMonthly = () => {
        setMonthlyModal(false);
        setUpdateMonthly(false)
        monthlyForm.resetFields()
    }

    const onDeleteMonthly = (sno) => {
        let idx = sno - 1
        if (idx >= 0) {
            coupons_monthly.splice(idx, 1)
            setCouponsMonthly([...coupons_monthly])
        }
        onCancelMonthly()
    }
    const onEditMonthly = (data) => {
        setUpdateMonthly([
            { name: 'coupon_code', value: data.coupon_code },
            { name: 'coupon_desc', value: data.coupon_desc },
            { name: 'sno', value: data.sno },
        ])
        setMonthlyModal(true)
    }

    const onMonthlySubmitForm = (data) => {
        let { coupon_code, coupon_desc } = data
        coupon_code = coupon_code.trim()
        coupon_desc = coupon_desc.trim()
        if (!updateMonthly) {
            coupons_monthly.push({ sno: coupons_monthly.length + 1, coupon_code, coupon_desc })
        } else {

            let coupon_item = updateMonthly.find((ite) => ite.name === 'sno')
            AppUtils.showMessage("updateMonthly ", coupon_item)
            if (coupon_item) {
                let sno = coupon_item.value
                let idx = sno - 1
                if (idx >= 0) {
                    coupons_monthly[idx].coupon_code = coupon_code
                    coupons_monthly[idx].coupon_desc = coupon_desc

                }
            }
        }

        setCouponsMonthly([...coupons_monthly])
        setMonthlyModal(false)
        setUpdateMonthly(false)
        monthlyForm.resetFields()

    }

    const onYearlySubmitForm = (data) => {
        let { coupon_code, coupon_desc } = data
        coupon_code = coupon_code.trim()
        coupon_desc = coupon_desc.trim()
        if (!updateYearly) {
            coupons_yearly.push({ sno: coupons_yearly.length + 1, coupon_code, coupon_desc })
        } else {

            let coupon_item = updateYearly.find((ite) => ite.name === 'sno')
            AppUtils.showMessage("updateYearly ", coupon_item)
            if (coupon_item) {
                let sno = coupon_item.value
                let idx = sno - 1
                if (idx >= 0) {
                    coupons_yearly[idx].coupon_code = coupon_code
                    coupons_yearly[idx].coupon_desc = coupon_desc

                }
            }
        }

        setCouponsYearly([...coupons_yearly])
        setYearlyModal(false)
        setUpdateYearly(false)
        yearlyForm.resetFields()

    }

    const onCancelYearly = () => {
        setYearlyModal(false);
        setUpdateYearly(false)
        yearlyForm.resetFields()
    }

    const onDeleteYearly = (sno) => {
        let idx = sno - 1
        if (idx >= 0) {
            coupons_yearly.splice(idx, 1)
            setCouponsYearly([...coupons_yearly])
        }
        onCancelYearly()
    }
    const onEditYearly = (data) => {
        setUpdateYearly([
            { name: 'coupon_code', value: data.coupon_code },
            { name: 'coupon_desc', value: data.coupon_desc },
            { name: 'sno', value: data.sno },
        ])
        setYearlyModal(true)
    }

    const PlanMonthly = <div className=''>

        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Android Plan Id</label>
                <Form.Item name='android_plan_id_monthly' rules={[{ required: true, whitespace: true, message: "Android plan should not be empty" }]} >
                    <Input placeholder="Enter android plan id" className='mt-2' type='text' />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>IOS Plan Id</label>
                <Form.Item name='ios_plan_id_monthly' rules={[{ required: true, whitespace: true, message: "IOS plan should not be empty" }]} >
                    <Input placeholder="Enter ios plan id" className='mt-2' type='text' />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Grace Period</label>
                <Form.Item name='grace_period_monthly' rules={[{ required: true, message: "Grace period should not be empty" }]}>
                    <Input placeholder="Enter grace period" className='mt-2' type='number' />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Grace Period Unit</label>
                <Form.Item name='grace_period_unit_monthly' rules={[{ required: true, message: "Grace period unit should not be empty" }]}>
                    <Select
                        className='mt-2'
                        defaultValue="days"
                        style={{
                            width: "100%",
                        }}
                        onChange={handleGracePeriod}
                        options={gracePeriod}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>

            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Price</label>
                <Form.Item name='price_monthly' rules={[{ required: true, message: "Price should not be empty" }]}>
                    <Input placeholder="Enter price" className='mt-2' type='number' />
                </Form.Item>
            </Col>
        </Row>
        <Row >
            <Divider />
        </Row>
        <Row >
            <Col md={24} lg={24} xs={24} sm={24}>
                <div className="pb-2 text-end">
                    <Button size={'small'} onClick={() => setMonthlyModal(true)} loading={updateBtnLoading} type="ghost" className="button_common" style={{ width: "auto" }}>+ Add Coupon</Button>
                </div>
            </Col>
        </Row>
        <Row >
            <Col md={24} lg={24} xs={24} sm={24} className='ps-2'>
                <Table
                    dataSource={coupons_monthly}
                    columns={columns_monthly}
                    pagination={false}
                    scroll={{ x: "100%" }}
                />
            </Col>
        </Row>

    </div>

    const PlanYearly = <div className=''>
        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Android Plan Id</label>
                <Form.Item name='android_plan_id_yearly' rules={[{ required: true, message: "Android plan should not be empty" }]} >
                    <Input placeholder="Enter android plan id" className='mt-2' type='text' />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>IOS Plan Id</label>
                <Form.Item name='ios_plan_id_yearly' rules={[{ required: true, message: "IOS plan should not be empty" }]} >
                    <Input placeholder="Enter ios plan id" className='mt-2' type='text' />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Grace Period</label>
                <Form.Item name='grace_period_yearly' rules={[{ required: true, message: "Grace period should not be empty" }]}>
                    <Input placeholder="Enter grace period" className='mt-2' type='number' />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Grace Period Unit</label>
                <Form.Item name='grace_period_unit_yearly' rules={[{ required: true, message: "Grace period unit should not be empty" }]}>
                    <Select
                        className='mt-2'
                        defaultValue="days"
                        style={{
                            width: "100%",
                        }}
                        onChange={handleGracePeriod}
                        options={gracePeriod}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>

            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Price</label>
                <Form.Item name='price_yearly' rules={[{ required: true, message: "Price should not be empty" }]}>
                    <Input placeholder="Enter price" className='mt-2' type='number' />
                </Form.Item>
            </Col>
        </Row>
        <Row >
            <Divider />
        </Row>
        <Row >
            <Col md={24} lg={24} xs={24} sm={24}>
                <div className="pb-2 text-end">
                    <Button size={'small'} onClick={() => setYearlyModal(true)} loading={updateBtnLoading} type="ghost" className="button_common" style={{ width: "auto" }}>+ Add Coupon</Button>
                </div>
            </Col>
        </Row>
        <Row >
            <Col md={24} lg={24} xs={24} sm={24} className='ps-2'>
                <Table
                    dataSource={coupons_yearly}
                    columns={columns_yearly}
                    pagination={false}
                    scroll={{ x: "100%" }}
                />
            </Col>
        </Row>
    </div>

    const items = [

        {
            key: '1',
            label: `Monthly`,
            children: <div>{PlanMonthly}</div>,
        },
        {
            key: '2',
            label: 'Yearly',
            children: <p>{PlanYearly}</p>,
        }
    ];

    const updateValues = () => {
        setUpdateFields([
            {
                name: ["grace_period_unit_monthly"],
                value: "days"
            },
            {
                name: ["grace_period_unit_yearly"],
                value: "days"
            },
        ])
    }

    function getSubscriptionFeatures() {
        setLoading(true)
        const successCb = (response) => {
            setLoading(false)
            // setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     total: response?.data?.[0]?.totalResults,
            // }));

            let features = response?.data?.data ?? []
            console.log("features ", features)
            setSubscriptionFeatures([...features])
        }
        const failureCb = (response) => {
            setLoading(false)
            setSubscriptionFeatures([])
        }
        postCall(null, "admin/dynaminSubscription/features", successCb, failureCb)
    }

    const onSwitchChange = (checked, index) => {
       
        subscriptionFeatures[index].enabled = ""+checked
        setSubscriptionFeatures([...subscriptionFeatures])
        // console.log("subscriptionFeatures", subscriptionFeatures)
    }
    
    const onCheckChange = (checked, index) => {
       
        subscriptionFeatures[index].is_unlimited = checked
        setSubscriptionFeatures([...subscriptionFeatures])
        // console.log("subscriptionFeatures", subscriptionFeatures)
    }
    const onChangeText = (text, index) => {
        console.log(text, index)
        subscriptionFeatures[index].quantity = text
        setSubscriptionFeatures([...subscriptionFeatures])
    }

    useEffect(() => {
        updateValues()
        getSubscriptionFeatures()
    }, [])


    return (
        <div className='Maincard p-3'>
            <Form onFinish={updateFuncFree} form={form} fields={updateFields}>
                <Spin spinning={loading}>
                    <Row>
                        <Col md={12} lg={12} xs={24} sm={24}>
                            <h4 className='fw-bold mt-2'>Subscription Features</h4>
                        </Col>
                        <Col md={12} lg={12} xs={24} sm={24}>
                            <div className="mt-2 text-end">
                                <Button onClick={() => onUpdateForm()} loading={updateBtnLoading} type="ghost" className="button_common" style={{ width: "auto" }}>+ Add Plan</Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col md={12} lg={12} xs={24} sm={24}>
                            <label className='fw-bold'>Plan Name</label>
                            <Form.Item name={'plan_name'} rules={[{ required: true, whitespace: true, message: "Plan name should not be empty" }]}>
                                <Input placeholder="Enter plan name" className='mt-2' />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} xs={24} sm={24}>
                            <label className='fw-bold'>Description</label>
                            <Form.Item name={'desc'} rules={[{ required: true, whitespace: true, message: "Plan description should not be empty" }]}>
                                <Input placeholder="Enter plan description" className='mt-2 ms-1' name='desc' />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row className='mt-3'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Collapse className='p-2' items={items} defaultActiveKey={['1', '2']} />
                        </Col>
                    </Row>


                    <Row className='mt-2'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <label className='fw-bold'>Features</label>
                            <div className="p-3">
                                {subscriptionFeatures.map((ite, index) => {
                                    return (
                                        <Row className='d-flex align-items-center justify-content-between p-1' key={index + 1}>
                                            <Col xs={16} md={16} lg={16} className='' style={{ fontSize: "large" }}>
                                                {ite.name}
                                            </Col>
                                            <Col xs={8} md={8} lg={8} className='d-flex align-items-center ps-3'>
                                                {ite.is_quantity ? (
                                                    <div className='d-flex flex-direction-row'>
                                                        <Form.Item name={ite.key} >
                                                            <Switch
                                                                checkedChildren={<CheckOutlined />}
                                                                unCheckedChildren={<CloseOutlined />}
                                                                onChange={(checked) => onSwitchChange(checked, index)}
                                                            />

                                                        </Form.Item>
                                                        {ite.enabled ==='true'  && (
                                                            <>
                                                            <Form.Item name={`${ite.key}_input`} className='ms-2'>

                                                                <Input type='number' placeholder='Enter value' onChange={(e) => onChangeText(e.target.value, index)} disabled={ite.is_unlimited} />

                                                            </Form.Item>
                                                            <Form.Item name={`${ite.key}_checkbox`} className='ms-2'>

                                                                <Checkbox defaultChecked={ite.is_unlimited} onChange={(e)=>onCheckChange(e.target.checked,index)} disabled={parseInt(ite.quantity)>0}>Unlimited</Checkbox>

                                                            </Form.Item>
                                                            </>
                                                        )}

                                                    </div>
                                                ) : (
                                                    <Form.Item name={ite.key} >
                                                        <Switch
                                                            checkedChildren={<CheckOutlined />}
                                                            unCheckedChildren={<CloseOutlined />}
                                                            onChange={(checked) => onSwitchChange(checked, index)}
                                                        />

                                                    </Form.Item>
                                                )}
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                    <Modal
                        title={updateMonthly ? "Update Coupon" : "Add New Coupon"}
                        centered
                        open={monthlyModal}

                        onCancel={() => onCancelMonthly()}
                        footer={null}
                        width={400}
                    >
                        <Form form={monthlyForm} onFinish={(data) => onMonthlySubmitForm(data)} fields={updateMonthly}>
                            <Form.Item name="coupon_code"

                                // initialValue={updateMonthly?.coupon_code ?? ""}
                                rules={[{ required: true, whitespace: true, message: `Please enter coupon code` }]} label="Coupon Code" labelCol={{ span: 24 }}>
                                <Input placeholder="Coupon Code" />
                            </Form.Item>
                            <Form.Item name="coupon_desc"

                                // initialValue={updateMonthly?.coupon_desc??""}
                                rules={[{ required: true, whitespace: true, message: `Please enter coupon description` }]} label="Coupon Description" labelCol={{ span: 24 }}>
                                <Input placeholder="Coupon Description" />
                            </Form.Item>

                            <Form.Item className='mx-auto mt-2'>
                                <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} htmlType='submit'>
                                    {!updateMonthly ? "Add" : "Update"}

                                </Button>
                                <Button danger type="primary" className='w-48 ' onClick={() => onCancelMonthly()}>Cancel</Button>
                            </Form.Item>
                        </Form>
                    </Modal>

                    <Modal
                        title={updateYearly ? "Update Coupon" : "Add New Coupon"}
                        centered
                        open={yearlyModal}

                        onCancel={() => onCancelYearly()}
                        footer={null}
                        width={400}
                    >
                        <Form form={yearlyForm} onFinish={(data) => onYearlySubmitForm(data)} fields={updateYearly}>
                            <Form.Item name="coupon_code"

                                // initialValue={updateMonthly?.coupon_code ?? ""}
                                rules={[{ required: true, whitespace: true, message: `Please enter coupon code` }]} label="Coupon Code" labelCol={{ span: 24 }}>
                                <Input placeholder="Coupon Code" />
                            </Form.Item>
                            <Form.Item name="coupon_desc"

                                // initialValue={updateMonthly?.coupon_desc??""}
                                rules={[{ required: true, whitespace: true, message: `Please enter coupon description` }]} label="Coupon Description" labelCol={{ span: 24 }}>
                                <Input placeholder="Coupon Description" />
                            </Form.Item>

                            <Form.Item className='mx-auto mt-2'>
                                <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} htmlType='submit'>
                                    {!updateYearly ? "Add" : "Update"}

                                </Button>
                                <Button danger type="primary" className='w-48 ' onClick={() => onCancelYearly()}>Cancel</Button>
                            </Form.Item>
                        </Form>
                    </Modal>


                </Spin>
            </Form>
        </div>
    )
}

export default DynamicSubscriptionNew