import { apiCall, apiCallWithRedux } from "../../components/ApiCall";
import { baseUrl, baseUrlLocal } from "../../constants/const";

export const mainAction = (url, method, data, successCb, failureCb, secure, shouldDispatch, dispatchType) => async (dispatch) => {
    const AdminToken = localStorage.getItem("access_token");
    let config = {
        method: method,
        url: `${baseUrl}${url}`,
    };
    if (data) {
        config.data = data
    }
    if (secure) {
        config.headers = { Authorization: `Bearer ${AdminToken}`, "Content-Type": "application/json" }
    } else {
        config.headers = { "Content-Type": "application/json" }
    }
    let response = await apiCall(config, dispatch);
    try {
        if (response?.status==200) {
            if (shouldDispatch) {
                dispatch({
                    type: dispatchType,
                    payload: response?.data
                })
            }

            successCb(response?.data);
        } else {
            failureCb(response?.data);
        }
    } catch (error) {
        failureCb(response?.data);
    }
};
