import { Avatar, Button, Checkbox, Col, Row, Select, Spin, Table, Typography, Empty } from "antd";
import React, { useEffect, useState } from "react";
import { ReloadOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import barGraph1 from "../assets/images/Bar Chart.svg"
import barGraph2 from "../assets/images/Bar Chart 1.svg"
import barGraph3 from "../assets/images/Bar Chart 2.svg"
import StackedAreaChart from "../components/StackAreaChart";
import { useDispatch } from "react-redux";
import { mainAction } from "../redux/action/mainAction";
import moment from "moment";
import { fileUrl } from "../constants/const";
import { DatePicker, Space } from 'antd';
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
const TipAnalytics = () => {
    const dispatch = useDispatch()
    const [refresLoading, setRefresLoading] = useState(false)
    const [tipValues,setTipValues] = useState({})
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [dropDwnValue, setDropDwnValue] = useState('weekly')
    const [tableData, setTableData] = useState([])
    const [totalPaid, setTotalPaid] = useState('')
    const [tableLoading, setTableLoading] = useState(false)
    const [graphLoading, setGraphLoading] = useState(false)
    const [graphData, setGraphData] = useState([])
    const [dates, setDates] = useState([dayjs(), dayjs().add(1, 'day')])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    })
    const getGraphData = () => {
        setGraphLoading(true)
        let payload = {
            type: dropDwnValue,
        }
        if (dropDwnValue == "custom" && dates?.length == 2) {
            payload.start_date = dates?.[0]
            payload.end_date = dates?.[1]
        }
        const successCb = (res) => {
            console.log("grphData", res);
            setGraphLoading(false)
            setGraphData(res)

        }
        const failureCb = (res) => {
            setGraphLoading(false)
            console.log("rerrerrr".res);
        }
        dispatch(mainAction('admin/tip/get_graph_by_type', 'post', payload, successCb, failureCb, true, false, ''))
    }
    const getUserList = (page, limit) => {
        setTableLoading(true)
        let payload = {
            type: dropDwnValue,
            page: page,
            limit: limit
        }
        if (dropDwnValue == "custom" && dates?.length == 2) {
            payload.start_date = dates?.[0]
            payload.end_date = dates?.[1]
        }
        const successCb = (res) => {
            setTableLoading(false)
            setTotalPaid(res?.totalPaid?.[0]?.total)
            setTableData(res?.user_list)
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: res?.other?.total_entries,
            }));
        }
        const failureCb = (res) => {
            setTableLoading(false)
            console.log("rerrerrr".res);

        }
        dispatch(mainAction('admin/tip/get_list_by_type', 'post', payload, successCb, failureCb, true, false, ''))
    }
    useEffect(() => {
        getGraphData()
        getUserList(pagination?.current, pagination?.pageSize)
    }, [dropDwnValue, dates])

    useEffect(()=>{
        getTips()
    },[])
    const getTips = () => {
        setRefresLoading(true)
        const successCb = (res) => {
            console.log("tipRes", res);
            setTipValues(res?.data)
            setRefresLoading(false)
        }
        const failureCb = (res) => {
            setRefresLoading(false)
        }
        dispatch(mainAction(`admin/tip/get_tip_summary`, 'get', '', successCb, failureCb, true, false, ''))
    }

    // Triggered on Change Page Number
    const handlePageChange = (page, pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: page,
            pageSize: pageSize,
        }));
        getUserList(page, pageSize)
    };


    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allUserIds = tableData.map(record => record._id);
            setSelectedUsers(allUserIds);
        } else {
            setSelectedUsers([]);
        }
    };


    const handleUserCheckboxChange = (userId) => {
        if (selectedUsers.includes(userId)) {
            setSelectedUsers(prevSelected => prevSelected.filter(id => id !== userId));
        } else {
            setSelectedUsers(prevSelected => [...prevSelected, userId]);
        }
    };

    const columns = [
        // {
        //     title: <Checkbox checked={selectedUsers?.length == tableData?.length && tableData?.length > 0} onChange={handleSelectAll} />,
        //     render: (value, object, indx) => <Checkbox checked={selectedUsers?.includes(object?._id)}
        //         onChange={() => handleUserCheckboxChange(object?._id)} />
        // },
        {
            title: 'Users',
            dataIndex: 'name',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.user_data?.firstName ?
                            <div>
                                <Avatar src={`${fileUrl}${object?.user_data?.profilePic}`} />
                                <span className="px-2">
                                    {
                                        `${object?.user_data?.firstName} ${object?.user_data?.lastName}`
                                    }
                                </span>
                            </div>
                            : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.user_data?.email ? object?.user_data?.email : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Paid At',
            dataIndex: 'paid',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.paidAt ? moment(object?.paidAt).format('MM-DD-YYYY') : "---"}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'paid',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.paidAmount ? `${object?.currency}${object?.paidAmount}` : '---'}</Typography>
                    </>
                )
            }
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'Action',
        //     key: 'Action',
        //     render: (value, object, indx) => {
        //         return (
        //             <>
        //                 <Button className='viewButton' onClick={() => handleView(object)}>View</Button>
        //             </>)
        //     },
        // },
    ]

    const handleDropDwn = (value) => {
        setDropDwnValue(value)
        if (value != 'custom') {
            getGraphData(value)
            getUserList(pagination?.current, pagination?.pageSize, value)
        }
    }
    const handleDateChange = (dates, dateString) => {
        console.log("dates-----", dates);
        setDates(dateString)
        // getGraphData(dropDwnValue, dateString)
        // getUserList(pagination?.current, pagination?.pageSize, dropDwnValue, dateString)
    };
    console.log("dates", dates);
    return (
        <>
            <div className="">
                <h5 className='fw-bold my-2'>Tip Analytics</h5>
                <div className="mt-4 crads">
                    <Row gutter={[30, 30]}>
                        <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                            <div className="Maincard p-3">
                                <div className="d-flex justify-content-between">
                                    <Typography className="total_paidText">Total Tips</Typography>
                                    <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} onClick={()=>getTips()}>
                                        <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                        <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                    </Typography>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div className="amountPrt">
                                        <Typography className="dollerAmount">{tipValues?.totalTips}
                                        </Typography>
                                        {/* <Typography className="fw-bold" style={{ color: 'red' }}>
                                            <ArrowDownOutlined style={{ marginRight: 5 }} />
                                            13.8%
                                        </Typography> */}
                                    </div>
                                    <img src={barGraph1} alt="bargraph_img" />
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <div className="Maincard p-3">
                                <div className="d-flex justify-content-between">
                                    <Typography className="total_paidText">Total Tips Amount  </Typography>
                                    <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} onClick={()=>getTips()}>
                                        <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                        <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                    </Typography>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div className="amountPrt">
                                        <Typography className="dollerAmount">${tipValues?.totalEarning}</Typography>
                                        {/* <Typography className="fw-bold" style={{ color: 'green' }}>
                                            <ArrowUpOutlined style={{ marginRight: 5 }} />
                                            11.3%
                                        </Typography> */}
                                    </div>
                                    <img src={barGraph2} alt="bargraph_img" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* gra[h area */}
                <Spin spinning={graphLoading}>
                    <div className="mt-4">
                        <h5 className='fw-bold my-2'>Paid amount Statistics</h5>
                        <div className="graphMainDiv mt-4 p-3">
                            <div className="customData d-flex justify-content-between align-items-center gap-3">
                                {console.log("total paid 123",graphData)}
                                <div className="amountBox px-3 py-2">
                                    <Typography className="fw-bold" >Total Amount</Typography>
                                    <Typography className="fw-bold" style={{ color: '#6C3BD5', fontSize: '1rem' }}>${totalPaid ?? "0.00"}</Typography>
                                </div>
                                <div className="">
                                    <div className="dropdwnDiv text-end">
                                        <Select
                                            // onChange={(value) => setDropDwnValue(value)}
                                            onChange={(value) => handleDropDwn(value)}
                                            style={{ borderRadius: 10, }}
                                            defaultValue={'weekly'}
                                            options={[
                                                {
                                                    label: "Weekly duration",
                                                    value: 'weekly'
                                                },
                                                {
                                                    label: "Monthly duration",
                                                    value: 'monthly'
                                                },
                                                {
                                                    label: "Custom duration",
                                                    value: 'custom'
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="mt-2">
                                        {
                                            dropDwnValue === "custom" &&
                                            <RangePicker defaultValue={dates} style={{border:'1px solid #bdadf0'}}
                                                onChange={handleDateChange} />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4">
                                { graphData.length>0 ? <StackedAreaChart graphData={graphData} type="tipAnalytics" dropDwnValue={dropDwnValue} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                            </div>
                        </div>
                    </div>
                </Spin>

                {/* table */}
                <div className="tablePart mt-5">
                    <h5 className='fw-bold my-2'>Users Manager</h5>
                    <Spin spinning={tableLoading}>
                        <div className="mt-3">
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                scroll={{ x: "250px" }}
                                pagination={{
                                    ...pagination,
                                    onChange: handlePageChange,
                                }}
                            />
                        </div>
                    </Spin>
                </div>
            </div >
        </>
    )
}

export default TipAnalytics;