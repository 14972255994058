import { Avatar, Button, Spin, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { fileUrl } from "../constants/const";
import moment from "moment";
import { mainAction } from "../redux/action/mainAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const HostTips = () => {
    const navigate= useNavigate()
    const dispatch = useDispatch()
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    })
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState([])
    const columns = [
        {
            title: 'Host Name',
            dataIndex: 'name',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.userDetail?.firstName ?
                            <div>
                                <Avatar src={`${fileUrl}${object?.userDetail?.profilePic}`} />
                                <span className="px-2">
                                    {
                                        `${object?.userDetail?.firstName} ${object?.userDetail?.lastName}`
                                    }
                                </span>
                            </div>
                            : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.userDetail?.email ? object?.userDetail?.email : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Total Tip',
            dataIndex: 'paid',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.totalTips ? object?.totalTips : "---"}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            render: (value, object, indx) => {
                return (
                    <>
                        <Button className='viewButton' onClick={() => navigate(`/tip_host_detail?id=${object?._id}`,{state:{totalTip:object?.totalTips}})}>View</Button>
                    </>)
            },
        },
    ]
    const getHostTips = (page, limit) => {
        setLoading(true)
        let payload = {
            page: page,
            limit: limit
        }
        const successCb = (res) => {
            console.log("resHostTp", res);
            setLoading(false)
            setTableData(res?.data)
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: res?.other?.total_entries,
            }));
        }
        const failureCb = (res) => {
            setLoading(false)
            console.log("rerrerrr".res);
        }
        dispatch(mainAction(`admin/tip/get_host_list?page=${page}&limit=${limit}`, 'get', payload, successCb, failureCb, true, false, ''))
    }

    useEffect(() => {
        getHostTips(pagination?.current, pagination?.pageSize)
    }, [])

    // Triggered on Change Page Number
    const handlePageChange = (page, pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: page,
            pageSize: pageSize,
        }));
        getHostTips(page, pageSize)
    };


    const handleView = () => {

    }
    return (
        <div className="">
            <h5 className='fw-bold my-2'>Tip To Host</h5>
            <Spin spinning={loading}>
                <div className="mt-4">
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        scroll={{ x: "250px" }}
                        pagination={{
                            ...pagination,
                            onChange: handlePageChange,
                        }}
                    />
                </div>
            </Spin>
        </div>
    )
}
export default HostTips