import { Avatar, Button, Input, Modal, Select, Spin, Table, Typography } from "antd"
import { useEffect, useState } from "react"
import { fileUrl } from "../constants/const"
import { BiSort } from "react-icons/bi"
import { mainAction } from "../redux/action/mainAction"
import { useDispatch } from "react-redux"
import { FaSortUp } from "react-icons/fa"
import { FaSortDown } from "react-icons/fa"
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { toast } from "react-toastify"

const Rewards = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [rewardModal, setRewardModal] = useState(false)
    const [hostDetail, setHostDetail] = useState({})
    const [sendBtnLoading, setSendBtnLoading] = useState(false)
    const [topSelect, setTopSelect] = useState('all')
    const [category, setCategory] = useState('')
    const [rewrdAmount, setRewrdAmount] = useState('')
    const [sendBtnDisabled, setSendBtnDisabled] = useState(false)
    const [selectFilter, setSelectFilter] = useState({
        value: '',
        number: '',
        colClick: ''
    })
    const [dropDwnValue, setDropDwnValue] = useState('all')
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    })
    const [tableData, setTableData] = useState([
        {
            userDetail: {
                firstName: "jhon",
                lastName: 'wick'
            },
            videos: 200,
            followers: 100,
            likes: 150,
            views: 80
        },
        {
            userDetail: {
                firstName: "jolina",
                lastName: 'gomez'
            },
            videos: 100,
            followers: 300,
            likes: 550,
            views: 480
        },
        {
            userDetail: {
                firstName: "harry",
                lastName: 'potter'
            },
            videos: 170,
            followers: 300,
            likes: 190,
            views: 380
        },
    ])
    const columns = [
        {
            title: 'Host',
            dataIndex: 'user',
            render: (value, object, indx) => {
                // console.log("object", object);
                return (
                    <>
                        <Typography className="fw-bold">{object?.hostDetail?.firstName ?
                            <div>
                                <Avatar src={`${fileUrl}${object?.hostDetail?.profilePic}`} />
                                <span className="px-2">
                                    {
                                        `${object?.hostDetail?.firstName} ${object?.hostDetail?.lastName}`
                                    }
                                </span>
                            </div>
                            : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: <div className="d-flex gap-2 align-items-center">
                <span>
                    Videos
                </span>
                <div className="d-flex flex-column">
                    <CaretUpOutlined size={10} style={{ cursor: "pointer", color: selectFilter?.value == "totalVideos" && selectFilter?.colClick && selectFilter?.number == -1 ? "#8151e9" : "grey" }} onClick={() => selectColumn('totalVideos', -1)} />
                    <CaretDownOutlined size={10} style={{ cursor: "pointer", color: selectFilter?.value == "totalVideos" && selectFilter?.colClick && selectFilter?.number == +1 ? "#8151e9" : "grey" }} onClick={() => selectColumn('totalVideos', +1)} />
                </div>
            </div>,
            dataIndex: 'videos',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.totalVideos ? object?.totalVideos : '0'}</Typography>
                    </>
                )
            },
        },
        {
            title: <div className="d-flex gap-2 align-items-center">
                <span>
                    Followers
                </span>
                <div className="d-flex flex-column">
                    <CaretUpOutlined size={10} style={{ cursor: "pointer", color: selectFilter?.value == "totalFollowers" && selectFilter?.colClick && selectFilter?.number == -1 ? "#8151e9" : "grey" }} onClick={() => selectColumn('totalFollowers', -1)} />
                    <CaretDownOutlined size={10} style={{ cursor: "pointer", color: selectFilter?.value == "totalFollowers" && selectFilter?.colClick && selectFilter?.number == +1 ? "#8151e9" : "grey" }} onClick={() => selectColumn('totalFollowers', +1)} />
                </div>
            </div>,
            dataIndex: 'followers',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.totalFollowers ? object?.totalFollowers : "0"}</Typography>
                    </>
                )
            }
        },
        {
            title: <div className="d-flex gap-2">
                <span>
                    Views
                </span>
                <div className="d-flex flex-column">
                    <CaretUpOutlined size={20} style={{ cursor: "pointer", color: selectFilter?.value == "totalViews" && selectFilter?.colClick && selectFilter?.number == -1 ? "#8151e9" : "grey" }} onClick={() => selectColumn('totalViews', -1)} />
                    <CaretDownOutlined size={20} style={{ cursor: "pointer", color: selectFilter?.value == "totalViews" && selectFilter?.colClick && selectFilter?.number == +1 ? "#8151e9" : "grey" }} onClick={() => selectColumn('totalViews', +1)} />
                </div>
            </div>,
            dataIndex: 'views',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.totalViews ? object?.totalViews : "0"}</Typography>
                    </>
                )
            }
        },
        {
            title: <div className="d-flex gap-2">
                <span>
                    Likes
                </span>
                <div className="d-flex flex-column">
                    <CaretUpOutlined size={20} style={{ cursor: "pointer", color: selectFilter?.value == "totalLikes" && selectFilter?.colClick && selectFilter?.number == -1 ? "#8151e9" : "grey" }} onClick={() => selectColumn('totalLikes', -1)} />
                    <CaretDownOutlined size={20} style={{ cursor: "pointer", color: selectFilter?.value == "totalLikes" && selectFilter?.colClick && selectFilter?.number == +1 ? "#8151e9" : "grey" }} onClick={() => selectColumn('totalLikes', +1)} />
                </div>
            </div>,
            dataIndex: 'likes',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.totalLikes ? object?.totalLikes : "0"}</Typography>
                    </>
                )
            }
        },
        // {
        //     title: <div className="d-flex gap-2">
        //         <span>
        //             Share
        //         </span>
        //         <div className="d-flex flex-column">
        //             <CaretUpOutlined style={{ cursor: "pointer", color: selectFilter?.value == "totalShares" && selectFilter?.number == -1 ? "skyblue" : "#8151e9" }} onClick={() => selectColumn('totalShares', -1)} />
        //             <CaretDownOutlined style={{ cursor: "pointer", color: selectFilter?.value == "totalShares" && selectFilter?.number == +1 ? "skyblue" : "#8151e9" }} onClick={() => selectColumn('totalShares', +1)} />
        //         </div>
        //     </div>,
        //     dataIndex: 'share',
        //     render: (value, object, indx) => {
        //         return (
        //             <>
        //                 <Typography className="fw-bold">{object?.totalShares ? object?.totalShares : "0"}</Typography>
        //             </>
        //         )
        //     }
        // },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            render: (value, object, indx) => {
                return (
                    <>
                        <Button className='viewButton' onClick={() => giveReward(object)}>Give reward</Button>
                    </>)
            },
        },
    ]

    const getData = (page, limit) => {
        setLoading(true)
        let payload = {
            page: page,
            limit: limit,
            // type:topSelect
        }
        if (selectFilter.value) {
            payload.sort = {
                [selectFilter.value]: selectFilter.number
            }
        }
        // if (topSelect && selectFilter.value != '') {
        //     payload.type = topSelect
        // }
        console.log("payload", payload);
        const successCb = (res) => {
            console.log("rewrd", res);
            setLoading(false)
            setTableData(res?.data)
            setPagination((prev) => ({
                ...prev,
                total: res?.other?.total_entries
            }))
        }
        const failureCb = (res) => {
            setLoading(false)
            console.log("rewrdErrr", res);
        }

        dispatch(mainAction('admin/reward/get_list', 'post', payload, successCb, failureCb, true, false, ''))
    }

    useEffect(() => {
        getData(pagination?.current, pagination?.pageSize)
    }, [])

    useEffect(() => {
        if (topSelect && selectFilter.value != '') {
            getData(pagination?.current, pagination?.pageSize)
        }
    }, [selectFilter, topSelect])

    const selectColumn = (value, number) => {
        setSelectFilter({
            value: value,
            number: number,
            colClick: true
        })
    }
    const chooseCategory = (value) => {
        setCategory(value)
        setSelectFilter((prev) => ({
            ...prev,
            value: value,
            number: -1
        }))
    }
    const topOnClick = (number) => {
        setTopSelect(number)
    }

    const selectOnChange = (value) => {
        console.log("value", value);
        setDropDwnValue(value)
        if (value == "all") {
            setSelectFilter('')
        }
    }

    const handlePageChange = (page, pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: page,
            pageSize: pageSize,
        }));
        getData(page, pageSize)
    };

    const giveReward = (data) => {
        setRewardModal(true);
        setHostDetail(data)
        if (!data?.hostDetail?.paypal_email) {
            setSendBtnDisabled(true)
        }
    }
    const handleSend = () => {
        setSendBtnLoading(true)
        let payload = {
            host_id: hostDetail?._id,
            amount: Number(rewrdAmount)
        }
        const successCb = (res) => {
            console.log("resssPaid", res);
            setSendBtnLoading(false)
            setRewardModal(false)
            if (res?.status == false) {
                toast.error(res?.message)
            } else {
                toast.success(res?.message)
            }
        }
        const failureCb = (res) => {
            console.log("errrr", res);
            setRewardModal(false)
            setSendBtnLoading(false)
            toast.error(res?.message)
        }
        dispatch(mainAction(`admin/reward/pay`, 'post', payload, successCb, failureCb, true, false, ''))
    }
    return (
        <Spin spinning={loading}>
            <div className="">
                <div className="filterMainDiv p-3">
                    <div className="d-flex align-items-center gap-5">
                        <Typography className="fw-bold" style={{ width: '62px' }}>Host</Typography>
                        <div className="d-flex gap-4">
                            <Typography className="topUsers px-4 py-1" style={{ backgroundColor: topSelect == 10 ? "#8151e9" : "white", cursor: 'pointer' }} onClick={() => topOnClick(10)}>Top 10</Typography>
                            <Typography className="topUsers px-4 py-1" style={{ backgroundColor: topSelect == 50 ? "#8151e9" : "white", cursor: 'pointer' }} onClick={() => topOnClick(50)}>Top 50</Typography>
                            <Typography className="topUsers px-4 py-1" style={{ backgroundColor: topSelect == 100 ? "#8151e9" : "white", cursor: 'pointer' }} onClick={() => topOnClick(100)}>Top 100</Typography>
                            <Typography className="topUsers px-4 py-1" style={{ backgroundColor: topSelect == 'all' ? "#8151e9" : "white", cursor: 'pointer' }} onClick={() => topOnClick('all')}>All</Typography>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-5 mt-4">
                        <Typography className="fw-bold">Category</Typography>
                        <div className="d-flex gap-4">
                            <Typography className="topUsers px-4 py-1" style={{ backgroundColor: category == "totalViews" ? "#8151e9" : "white", cursor: 'pointer' }} onClick={() => chooseCategory("totalViews")}>By Views</Typography>
                            <Typography className="topUsers px-4 py-1" style={{ backgroundColor: category == "totalFollowers" ? "#8151e9" : "white", cursor: 'pointer' }} onClick={() => chooseCategory("totalFollowers")}>By Followers</Typography>
                            <Typography className="topUsers px-4 py-1" style={{ backgroundColor: category == "totalLikes" ? "#8151e9" : "white", cursor: 'pointer' }} onClick={() => chooseCategory("totalLikes")}>By Likes</Typography>
                            <Typography className="topUsers px-4 py-1" style={{ backgroundColor: category == "totalVideos" ? "#8151e9" : "white", cursor: 'pointer' }} onClick={() => chooseCategory("totalVideos")}>No of Videos</Typography>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                    <h5 className='fw-bold'>Host List</h5>
                    {/* <div className="">
                        <Select
                            style={{
                                width: 150
                            }}
                            onChange={(value) => selectOnChange(value)}
                            defaultValue={'all'}
                            options={[
                                {
                                    label: "All",
                                    value: 'all'
                                },
                                {
                                    label: "Top 10",
                                    value: '10'
                                },
                                {
                                    label: "Top 50",
                                    value: '50'
                                },
                            ]}
                        />
                    </div> */}
                </div>
                <div className="mt-3">
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        scroll={{ x: "250px" }}
                        pagination={{
                            ...pagination,
                            onChange: handlePageChange,
                        }}
                    />
                </div>

                {/* Give Reward*/}
                {console.log("hostDetail", hostDetail)}
                {
                    rewardModal &&
                    <Modal
                        centered
                        open={rewardModal}
                        onCancel={() => setRewardModal(false)}
                        footer={null}
                        width={400}
                        title={<h6 className="fw-bold">Give Reward</h6>}
                    >
                        <div className="mt-4" style={{ height: 300 }}>
                            <div className="d-flex gap-2">
                                <Typography className="fw-bold">Name: </Typography>
                                <Typography> {
                                    hostDetail?.hostDetail?.firstName ?
                                        `${hostDetail?.hostDetail?.firstName} ${hostDetail?.hostDetail?.lastName}`
                                        : "---"
                                }</Typography>
                            </div>
                            <div className="d-flex gap-2 mt-2">
                                <Typography className="fw-bold">Email: </Typography>
                                <Typography>
                                    {
                                        hostDetail?.hostDetail?.paypal_email ? hostDetail?.hostDetail?.paypal_email : '---'
                                    }
                                </Typography>
                            </div>
                            <div className="mt-5 rewrdInput">
                                <Typography className="fw-bold">Send Reward</Typography>
                                <Input className="mt-2" prefix={'$'} value={rewrdAmount} onChange={(e) => setRewrdAmount(e.target.value)} />
                                {
                                    sendBtnDisabled &&
                                    <Typography className="text-danger fw-bold mt-2">Host's Payal Account not Linked. Can not transfer money!
                                    </Typography>
                                }
                            </div>

                            <div className="mt-5 d-flex gap-4">
                                <Button disabled={sendBtnDisabled} loading={sendBtnLoading} type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={() => handleSend()}>Send</Button>
                                <Button danger type="primary" className='w-48 ' onClick={() => { setRewardModal(false) }}>Cancel</Button>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        </Spin>
    )
}
export default Rewards