import { Button, Form, Row, Table, message, Spin, Modal, Input, Card, Switch, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import ModalQuestion from '../components/ModalQuestion';
import { deleteCall, getDataCall, getDataCallResult, postAddCategory, postCall, postCallPatch, updateCatgoryStatusForStream } from '../components/CommonApiCall';
import Search from 'antd/es/input/Search';
import logoDel from '../assets/images/delete.png'
import { fileUrl } from '../constants/const';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import AppUtils from '../Utils/AppUtils';

const dataSource = [
    {
        name: "First Category",
        image: { logoDel }
    }
]

function Categories() {
    const [searchText, setSearchText] = useState('');
    const [form] = Form.useForm();
    const [data, setData] = useState();
    const imageRef = useRef(null)
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [delId, setDelId] = useState(null);
    const [editData, setEditData] = useState(null);
    const [type, setType] = useState(null);
    const [viewModal, setViewModal] = useState(false);
    const [viewData, setViewData] = useState('');
    const [loading, setLoading] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)
    const [selectedThumbnail, setSelectedThumbnail] = useState('')
    const [editThumbnail, setEditThumbnail] = useState('')
    const [adminData, setAdminData] = useState({})
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        position: ['bottomCenter'],
        size: 'small'
    });
    const [openRplyModal, setOpenRplyModal] = useState();

    useEffect(() => {
        getData();
    }, [pagination?.current, pagination?.pageSize, searchText])

    useEffect(()=>{
     getAdminProfile()
    },[])

    useEffect(() => {
        if (editThumbnail) {
            if (typeof editThumbnail !== "string") {
                (async function () {
                    let abc = URL.createObjectURL(editThumbnail)
                    console.log("abc", abc)
                    setSelectedThumbnail(abc)
                })()
            }
        }
    }, [editThumbnail])

    //** Called to fetch the users data record **//
    const getData = async () => {
        setLoading(true)
        const successCb = (response) => {
            setLoading(false)
            // setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     total: response?.data?.[0]?.totalResults,
            // }));
            let datas = response?.data.map((item) => {
                return ({
                    _id: item?._id,
                    key: item?._id,
                    name: item?.categoryName,
                    image: item?.categoryImage,
                    status: item?.status
                })
            });

            setData(datas)
        }
        const failureCb = (response) => {
            setLoading(false)
            setData('')
            setPagination({
                current: 1,
                total: 0,
                pageSize: 5,
            });
        }
        getDataCall(pagination?.current, pagination?.pageSize, searchText, "/workouts/list", successCb, failureCb)
    }

    // get admin profile

    const getAdminProfile=()=>{
        let user = window.localStorage.getItem('user_data')
        user = JSON.parse(user)
        
        let url = `admin/user/${user._id}`
        const successCb = (res) => {
          setLoading(false)
          
          let adminData = res.data.data
          AppUtils.showMessage("admin profile",adminData)
          setAdminData({...adminData})
        }
        const failureCb = (res) => {
          setLoading(false)
        }
        getDataCall(null, null, null,  url, successCb, failureCb)
    }

    const onViewClick = (record) => {
        setViewModal(true)
        setViewData(record)
    }

    const onToggleChange = (checked, id) => {
        console.log(`switch to ${checked}`);
        let updatedStatus;
        if (checked == true) updatedStatus = "1";
        else updatedStatus = "0";
        let data = {
            status: updatedStatus,
        };
        console.log("updated title", data)
        let url = `workouts/${id}`
        const successCb = (res) => {
            setLoading(false)
            getData()
            message.success("Category Updated Successfully")
            form.resetFields()
            setOpenModal(false)
        }
        const failureCb = (res) => {
            setLoading(false)
        }
        postCallPatch(data, url, successCb, failureCb)

    };

    const onCancel = () => {
        setOpenModal(false);
        form.resetFields();
        setType(null);
        setEditData(null)
        setSelectedThumbnail('');
        setEditThumbnail('');
    }

    const onDeleteOk = () => {
        setLoadingModal(true)
        let url = '/workouts/'
        const successCb = (res) => {
            setLoadingModal(false)
            getData()
            message.success("Category Deleted Successfully")
            form.resetFields()
            setDelId(null)
            setOpenDeleteModal(false)
        }
        const failureCb = (res) => {
            setLoadingModal(false)
        }
        deleteCall(url, delId, successCb, failureCb)
    }

    // columns for the table //
    const columns = [
        {
            title: 'Category Name',
            dataIndex: 'name',
            key: 'name',
            align: "center"
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            align: "center",
            render: (image) => { return (image && <img src={`${fileUrl}${image}`} alt="" style={{ width: "180px" }} />) }
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            align: "center",
            render: (_, record) => {
                const onToggle = (checked) => {
                    onToggleChange(checked, record?._id)
                };
                return (
                    <>
                        <Tooltip title="Toogle to Change Category Status">
                            <>
                                <Switch className='me-2' defaultChecked={record.status == "1" ? true : false} onChange={onToggle} />
                            </>
                        </Tooltip>
                        <Tooltip title="View Category">
                            <>
                                <i class="bi bi-binoculars me-2" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onViewClick(record)}></i>
                            </>
                        </Tooltip>
                        <Tooltip title="Edit Category">
                            <>
                                <i class="bi bi-pencil-square me-2" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onEditClick(record)}></i>
                            </>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <>
                                <i class="bi bi-trash" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onDeleteClick(record?._id)}></i>
                            </>
                        </Tooltip>
                    </>)
            },
        },
    ];

    const onAddClick = () => {
        console.log("Add icon clicked")
        setType("add")
        form.setFieldsValue({
            Name: '',
        })
        setOpenModal(true)
    }
    const onEditClick = (record) => {
        console.log("record id", record)
        setType("edit")
        setEditData(record);
        form.setFieldsValue({
            Name: record?.name,
        })
        setOpenModal(true)
    }
    const onDeleteClick = (id) => {
        console.log("id", id)
        setDelId(id)
        setOpenDeleteModal(true)
    }

    const handleAddOk = (values) => {
        console.log("add values", values, editThumbnail)
        if (!editThumbnail) {
            message.error("Please Choose the Category Image")
        }
        let data = { file: editThumbnail }
        let url = "uploads/photos"
        const successCb = (res) => {
            // setLoading(false)
            console.log("res upload", res)
            let data = {
                categoryName: values?.Name,
                categoryImage: res?.data[0]?.key,
            };
            console.log("datata", data)
            let url = "workouts"
            const successCb = (res) => {
                setLoading(false)
                getData()
                message.success("Category Added Successfully")
                form.resetFields()
                setDelId(null)
                setSelectedThumbnail('');
                setEditThumbnail('');
                setOpenModal(false)
            }
            const failureCb = (res) => {
                setLoading(false)
            }
            postCall(data, url, successCb, failureCb)
        }
        const failureCb = (res) => {
            setLoading(false)
        }
        postAddCategory(data, url, successCb, failureCb)

    }

    const handleEditOk = (values) => {
        console.log("add values", values)
        // setOpenModal(false)
        if (editThumbnail) {
            let data = { file: editThumbnail }
            let url = "uploads/photos"
            const successCb = (res) => {
                setLoading(false)
                console.log("res upload", res)
                let data = {
                    categoryName: values?.Name,
                    categoryImage: res?.data[0]?.key,
                };
                console.log("updaeted image", data)
                let url = `workouts/${editData?._id}`
                const successCb = (res) => {
                    setLoading(false)
                    getData()
                    message.success("Category Updated Successfully")
                    form.resetFields()
                    setDelId(null)
                    setSelectedThumbnail('');
                    setEditData(null)
                    setEditThumbnail('');
                    setOpenModal(false)
                }
                const failureCb = (res) => {
                    setLoading(false)
                }
                postCallPatch(data, url, successCb, failureCb)
            }
            const failureCb = (res) => {
                setLoading(false)
            }
            postAddCategory(data, url, successCb, failureCb)
        } else {
            let data = {
                categoryName: values?.Name,
            };
            console.log("updated title", data)
            let url = `workouts/${editData?._id}`
            const successCb = (res) => {
                setLoading(false)
                getData()
                message.success("Category Updated Successfully")
                form.resetFields()
                setDelId(null)
                setSelectedThumbnail('');
                setEditData(null)
                setEditThumbnail('');
                setOpenModal(false)
            }
            const failureCb = (res) => {
                setLoading(false)
            }
            postCallPatch(data, url, successCb, failureCb)
        }

    }


    // Triggered on Change Page Number
    const handlePageChange = (page, pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: page,
            pageSize: pageSize,
        }));
    };

    //*** to handle the Search Value ***//
    const handleSearch = (value) => {
        setSearchText(value);
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: 1,
        }));
    };

    const onCatUpdate=(checked)=>{
        let data = { category_status: checked?"1":"0" }
        let url = "admin/update_category_status"
        setLoading(true)
        const successCb = (res) => {
            AppUtils.showMessage("success res ",res)
            let responseData = res.data
            message.success(responseData.message)
            setLoading(false)
            getAdminProfile()
        }
        const failureCb = (res) => {
            AppUtils.showMessage("failure res ",res)
            setLoading(false)
            message.error("Unable to update category !!!")
        }
        updateCatgoryStatusForStream(url, data, successCb, failureCb)
    }

    return (
        <div>
            {loading && <Spin size="large" className='loader' />}
            {loadingModal && <Spin size="large" className='loaderModal' />}
            <Row className='justify-content-between align-items-center my-2'>
                <h4 className='fw-bold'>Categories</h4>
                <div className='d-flex flex-row justify-content-center align-items-center '>
                   {adminData._id &&(
                    <>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        // defaultChecked={(adminData && adminData?.category_status==='1')?true:false}
                        value={(adminData && adminData?.category_status==='1')?true:false}
                        onChange={(checked)=>onCatUpdate(checked)}
                    />
                    <label className='ms-2 me-3'>Make Stream Visible To All</label>
                    </>
                   )} 
                    

                    <Button className="button_common" style={{ width: "auto" }} onClick={() => onAddClick()}>Add Category</Button>

                </div>

                {/* <Search
                    placeholder="Search by name.."
                    onSearch={handleSearch}
                    enterButton
                    allowClear
                    style={{  float: "right", marginBottom: "10px" }}
                /> */}
            </Row>
            <Table
                dataSource={data}
                columns={columns}
                scroll={{ x: "250px" }}
                pagination={{
                    ...pagination,
                    onChange: handlePageChange,
                }} />

            <Modal
                centered
                open={openDeleteModal}
                onCancel={() => setOpenDeleteModal(false)}
                footer={null}
                width={350}
            >
                <div className='text-center'>
                    <img src={logoDel} alt="delete_icon" style={{ width: "25%" }} />
                </div>
                <h5 className='text-center'>Delete Category</h5>
                <p>Are you sure you want ot Delete this Category?</p>
                <div>
                    <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onDeleteOk} >Delete</Button>
                    <Button danger type="primary" className='w-48 ' onClick={() => { setOpenDeleteModal(false); }}>Cancel</Button>
                </div>
            </Modal>
            <Modal
                title={type == "add" ? "Add New Category" : "Update Category"}
                centered
                open={openModal}
                key={editData?.image}
                onCancel={() => onCancel()}
                footer={null}
                width={400}
            >
                <Form form={form} onFinish={type == "add" ? handleAddOk : handleEditOk} >
                    <Form.Item name="Name"
                        initialValue={editData?.name ?? ""}
                        rules={[{ required: true, message: `Please enter the category name` }]} label="Category Name" labelCol={{ span: 24 }}>
                        <Input placeholder="Category Name" />
                    </Form.Item>
                    <Form.Item name="image"
                        initialValue={editData?.image ?? ""}
                        labelCol={{ span: 24 }}>
                        <label className="select_file ml-2" >
                            <input type="file" accept="image/*" ref={imageRef} style={{ display: 'none' }} onChange={(event) => setEditThumbnail(event.target.files[0])} ></input>
                        </label>
                        <Button type="primary" className='' style={{ backgroundColor: "#6240C9" }} onClick={() => imageRef.current.click()}>Select Image</Button>
                    </Form.Item>
                    {(selectedThumbnail || editData?.image) && <img id="selectedImage" src={selectedThumbnail ? selectedThumbnail : `${fileUrl}${editData.image}`} alt="" style={{ height: '200px', width: '300px', pointerEvents: "none", objectFit: "contain", backgroundColor: "rgba(215,215,215,1)" }} />}
                    <Form.Item className='mx-auto mt-2'>
                        <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} htmlType='submit'>{type == "add" ? "Add" : "Update"}</Button>
                        <Button danger type="primary" className='w-48 ' onClick={() => onCancel()}>Cancel</Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                centered
                open={viewModal}
                onCancel={() => setViewModal(false)}
                footer={null}
                width={400}
            > <Card
                hoverable
                style={{
                    width: 340,
                }}
                cover={<img alt="example" src={`${fileUrl}${viewData?.image}`} />}
            >
                    <Card.Meta title={viewData?.name} />
                </Card>

            </Modal>
        </div>
    )
}

export default Categories