import { Button, Table, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { getDataDashboard, postCall } from '../components/CommonApiCall';

const datasource = [
  {
    key:1,
    name : "Ram",
    email:"ram@ram.com",
    reason : "Already Fit"
  }
]

function AccountDeleteRequest() {
  const [searchText, setSearchText] = useState("");
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: ['bottomCenter'],
    size: 'small'
  });

  useEffect(() => {
    getData();
  }, [pagination?.current, pagination?.pageSize, searchText])

  //** Called to fetch the users data record **//
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response?.data?.totalResults,
      }));
      // let data = response?.data?.results.map((item) => {
      //   let firstName = item?.firstName ?? "--";
      //   let lastName = item?.lastName ?? "";
      //   let phone = item?.phoneNumber ?? "--"
      //   let country = item?.countryCode ? `+ ${item?.countryCode}` : "";
      //   return ({
      //     _id: item?._id,
      //     key: item?._id,
      //     Users: `${firstName} ${lastName}`,
      //     Phone_number: phone,
      //     Country: country,
      //     Email: item?.email,
      //     status: item?.status
      //   })
      // })
      // setUserData(data);
    }
    const failureCb = (response) => {
      setLoading(false)
      setUserData('')
      setPagination({
        current: 1,
        total: 0,
        pageSize: 5,
      });
    }
    // getDataDashboard(pagination?.current, pagination?.pageSize, "/admin/dashboard", searchText, successCb, failureCb);
  }


  // *** Page change ******//
  const handlePageChange = (page, pageSize) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page,
      pageSize: pageSize,
    }));
  };

  const  handleApprove = ( record) =>{
    console.log("approve " , record);
    setLoading(true);
    // let data = {
    //   reportUser: reportUser,
    //   reportId: unsuspend?._id,
    //   unSuspend: "1"
    // }
    return false;
    let url = '/reports/suspendReportedUser'
    const successCb = (res) => {
      setLoading(false)
      getData()
      // setOpenUserDetails(false)
      // setOpenUnsuspendModal(false)
      // setUnsuspend(null)
      message.success("User Unsuspended Successfully")
    }
    const failureCb = (res) => {
      setLoading(false)
      // setOpenUnsuspendModal(false)
      // setOpenUserDetails(false)
      message.error("Something wrong happened, Try again")
      // message.error(res?.data?.message)
    }
    // postCall(data, url, successCb, failureCb)
  }

  // columns for the table //
  const columns = [
    {
      title: 'Users',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      align: "center",
      render: (_, record) => {
        return (
          <>
            <Button className='purpleButton px-4' onClick={()=>handleApprove(record?._id)}>Approve</Button>
          </>)
      },
    },
  ];

  return (
    <div className='Maincard p-3'>
      <h5 className='fw-bold mb-3'>Delete Account Requests</h5>
      <Table
        dataSource={datasource}
        columns={columns}
        scroll={{ x: "250px" }}
        pagination={{
          ...pagination,
          onChange: handlePageChange,
        }}
      />
    </div>
  )
}

export default AccountDeleteRequest