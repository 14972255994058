import { Col, Row } from 'antd'
import React from 'react'
import { BsArrowUpCircleFill } from "react-icons/bs";
import VideoPlayer from '../components/VideoPlayer';
import thumbnail from '../assets/images/backgourndWorkput.png'

function StreamAnalysis() {
  return (
    <div className='Maincard p-3'>
      <h4 className='fw-bold'>Streaming Analysis</h4>
      <div>
        <Row className='justify-content-between align-items-center'>
          <Col xs={12} sm={12} md={5} lg={5} className='Maincard p-3'>
            <p className='fw-bold'>Views</p>
            <div>0 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} className='Maincard p-3'>
            <p className='fw-bold'>Likes</p>
            <div>0 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} className='Maincard p-3'>
            <p className='fw-bold'>Comments</p>
            <div>0 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} className='Maincard p-3'>
            <p className='fw-bold'>Shares</p>
            <div>0 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
          </Col>
        </Row>
        <h5 className='fw-bold my-3'>Videos</h5>
        <div className='Maincard p-2'>
          <Row className='justify-content-around'>
            <Col xs={24} sm={24} md={11} lg={11} className='p-3 m-1 Maincard d-flex flex-column justify-content-between'>
              <Row className='p-2'>
                <Col xs={24} sm={24} md={24} lg={10}>
                  {/* {(video?.video || video?.thumbnail) && */}
                  <VideoPlayer videoUrl={"video?.video"} thumbnailLink={thumbnail} streamType={"video"} />
                  {/* } */}
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <div className='p-1 pb-0'><span className='fw-bold'>Title: </span>{"Runner Video"}</div>
                  <div className='p-1 pb-0' style={{ wordBreak: "break-word" }}><span className='fw-bold'>Uploaded On: </span>{"04-05-2023"}</div>
                  {/* <div className='p-1 pb-0'><span className='fw-bold'>Category: </span>{video?.channelCategory}</div>
                          <div className='p-1 pb-0'><span className='fw-bold'>Type: </span>{video?.broadcastType}</div> */}
                </Col>
              </Row>
              <div className='line_class'></div>
              <Row className='mt-3'>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>View</div>
                  <div>99 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>Likes</div>
                  <div>9 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>Comments</div>
                  <div>29 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>Share</div>
                  <div>15 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} className='p-3 m-1 Maincard d-flex flex-column align-items-between'>
              <Row className='p-2'>
                <Col xs={24} sm={24} md={24} lg={10}>
                  {/* {(video?.video || video?.thumbnail) && */}
                  <VideoPlayer videoUrl={"video?.video"} thumbnailLink={thumbnail} streamType={"video"} />
                  {/* } */}
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <div className='p-1 pb-0'><span className='fw-bold'>Title: </span>{"Squad"}</div>
                  <div className='p-1 pb-0' style={{ wordBreak: "break-word" }}><span className='fw-bold'>Uploaded On: </span>{"04-05-2023"}</div>
                  {/* <div className='p-1 pb-0'><span className='fw-bold'>Category: </span>{video?.channelCategory}</div>
                          <div className='p-1 pb-0'><span className='fw-bold'>Type: </span>{video?.broadcastType}</div> */}
                </Col>
              </Row>
              <div className='line_class'></div>
              <Row className='mt-3'>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>View</div>
                  <div>100 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>Likes</div>
                  <div>25 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>Comments</div>
                  <div>15 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>Share</div>
                  <div>5 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default StreamAnalysis