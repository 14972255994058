import { Button, Table } from 'antd'
import React from 'react'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';


const datasource = [
    {
        key: "1",
        backup: "backup_01",
        createdAt: "5-11-2023",
        download: "Downlaod"
    },
    {
        key: "2",
        backup: "backup_02",
        createdAt: "01-11-2023",
        download: "Downlaod2"
    },
]

function Backup() {

    const columns = [
        {
            title: 'Backup',
            dataIndex: 'backup',
            key: 'backup',
            // align:"center",
        },
        {
            //     title: <div className="d-flex gap-2">
            //     <span>
            //         Created At
            //     </span>
            //     <div className="d-flex flex-column">
            //         <CaretUpOutlined size={20} 
            //                 style={{ cursor: "pointer",}} 
            //                                             // color: selectFilter?.value == "totalLikes" && selectFilter?.colClick && selectFilter?.number == -1 ? "#8151e9" : "grey" }} 
            //                                             // onClick={() => selectColumn('totalLikes', -1)} 
            //                                             />
            //         <CaretDownOutlined size={20} 
            //                     style={{ cursor: "pointer",}} 
            //                         // color: selectFilter?.value == "totalLikes" && selectFilter?.colClick && selectFilter?.number == +1 ? "#8151e9" : "grey" }} onClick={() => selectColumn('totalLikes', +1)}
            //                      />
            //     </div>
            // </div>,
            title: "Created At",
            dataIndex: 'createdAt',
            key: 'createdAt',
            // align: "center",
            // render : (record) => moment(record?.paidAt).format('MM-DD-YYYY') 
        },
        {
            title: 'Download',
            dataIndex: 'download',
            align: "center",
            key: 'download',
            render: (record) => {
                return (<Button className='purpleButton' onClick={() => handleDownload(record)}>Download</Button>)
            }
        },
    ]

    function handleDownload(record) {
        // Replace 'your-file-url' with the URL or path to the file you want to download
        const fileUrl = record;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'filename.ext'; // specify the desired file name
        link.click();
    }

    const createBackup = () => {
        console.log("create Backup function trigerred")
    }
    return (
        <div className='Maincard p-3'>
            <div className='d-flex justify-content-between align-items-center'>
                <h4 className='fw-bold'>Data Backup</h4>
                <Button className="button_common" style={{ width: "auto" }} onClick={createBackup}>Create Backup</Button>
            </div>
            <Table
                dataSource={datasource}
                columns={columns}
                scroll={{ x: "250px" }}
                className='mt-2'
            />
        </div>
    )
}

export default Backup