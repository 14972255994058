import React from 'react';
import { Routes, Route, HashRouter } from 'react-router-dom';
import Home from '../screens/Home';
import Overview from '../screens/Overview';
import CustomNotification from '../screens/CustomNotification';
import Faq from '../screens/Faq';
import UserReporting from '../screens/UserReporting';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import ContactUs from '../screens/ContactUs';
import TermsOfService from '../screens/TermsOfService';
import Aknowledgement from '../screens/Aknowledgement';
import AboutUs from '../screens/AboutUs';
import TipAnalytics from '../screens/TipAnalytics';
import PayoutReq from '../screens/PayoutReq';
import HostTips from '../screens/HostTips';
import HostTipUserView from '../screens/HostTipUserView';
import Promoters from '../screens/Promoters';
import Leads from '../screens/Leads';
import AffiliationDashboard from '../screens/AffiliationDashboard';
import { ToastContainer } from 'react-toastify';
import Settings from '../screens/Settings';
import Advertisement from '../screens/Advertisement';
import Categories from '../screens/Categories';
import Rewards from '../screens/Rewards';
import RewardPaid from '../screens/RewardPaid';
import DynamicSubscriptionDashboard from '../screens/DynamicSubscriptionDashboard';
import DynamicSubscriptionList from '../screens/DynamicSubscriptionList';
import DynamicSubscriptionAdd from '../screens/DynamicSubscriptionNew';
import DynamicSubscriptionView from '../screens/DynamicSubscriptionView';
import DynamicSubscriptionEdit from '../screens/DynamicSubscriptionEdit';
import AccountDeleteRequest from '../screens/AccountDeleteRequest';
import App_Customization from '../screens/App_Customization';
import Web_Customization from '../screens/Web_Customization';
import Backup from '../screens/Backup';
import Repository from '../screens/Repository';
import StreamAnalysis from '../screens/StreamAnalysis';
import StreamingAnalysisUser from '../screens/StreamingAnalysisUser';

//  All routes accessible with token 
function CommonRoutes() {
  return (
    <>
        <Routes>
          {/* <Route path="/" element={ <Home/> } /> */}
          <Route path="/overview" element={<Overview />} />
          <Route path="/custom_notification" element={<CustomNotification />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/user_reporting" element={<UserReporting />} />
          {/* <Route path="/user_list" element={ <UserReporting/> } /> */}
          <Route path="/termsofservice" element={<TermsOfService />} />
          <Route path="/aknowledgement" element={<Aknowledgement />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/tip_analytics" element={<TipAnalytics />} />
          <Route path="/tip_payout_request" element={<PayoutReq />} />
          <Route path="/tip_host" element={<HostTips />} />
          <Route path="/tip_host_detail" element={<HostTipUserView />} />
          <Route path="/promoters" element={<Promoters />} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/affiliation_dashboard" element={<AffiliationDashboard />} />
          <Route path="/advertisement" element={<Advertisement />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/rewards" element={<Rewards />} />
          <Route path="/paid_rewards" element={<RewardPaid />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/dynamic_subscription" element={<DynamicSubscriptionList />} />
          <Route path="/dynamic_subscription_dashboard" element={<DynamicSubscriptionDashboard />} />
          <Route path="/dynamic_subscription_add" element={<DynamicSubscriptionAdd />} />
          <Route path="/dynamic_subscription_view/:planName" element={<DynamicSubscriptionView />} />
          <Route path="/dynamic_subscription_edit/:planId" element={<DynamicSubscriptionEdit />} />
          <Route path="/delete_account" element={<AccountDeleteRequest />} />
          <Route path="/app_customisation" element={<App_Customization />} />
          <Route path="/web_customisation" element={<Web_Customization />} />
          <Route path="/backup" element={<Backup />} />
          <Route path="/repository" element={<Repository />} />
          <Route path="/streaming_analysis" element={<StreamAnalysis />} />
          <Route path="/streaming_analysis_user" element={<StreamingAnalysisUser />} />
        </Routes>
       
    </>
  )
}

export default CommonRoutes
