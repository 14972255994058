import { Avatar, Breadcrumb, Button, Col, Row, Spin, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { UserOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { mainAction } from "../redux/action/mainAction";
import { fileUrl } from "../constants/const";

const HostTipUserView = ({ user }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get('id')
    const [hostDetail, setHostDetail] = useState({})
    const [loading, setLoading] = useState(false)
    const getUserDetail = () => {
        setLoading(true)
        const successCb = (res) => {
            setLoading(false)
            setHostDetail(res)
        }
        const failureCb = (res) => {
            setLoading(false)
        }
        dispatch(mainAction(`admin/tip/get_host_detail?user_id=${userId}`, 'get', '', successCb, failureCb, true, false, ''))
    }

    const columns = [
        {
            title: 'Paid By',
            dataIndex: 'name',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.userData?.[0]?.firstName ?
                            <div>
                                <Avatar src={`${fileUrl}${object?.userData?.[0]?.profilePic}`} />
                                <span className="px-2">
                                    {
                                        `${object?.userData?.[0]?.firstName} ${object?.userData?.[0]?.lastName}`
                                    }
                                </span>
                            </div>
                            : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.userData?.[0]?.email ? object?.userData?.[0]?.email : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Paid at',
            dataIndex: 'paid',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.paidAt ? moment(object?.paidAt).format('MM-DD-YYYY') : "---"}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Tip',
            dataIndex: 'paid',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.paidAmount ? object?.paidAmount : '---'}</Typography>
                    </>
                )
            }
        },
    ]
    useEffect(() => {
        getUserDetail()
    }, [])

    return (
        <div className="">
            <div className="">
                <Breadcrumb className="mb-3">
                    <Breadcrumb.Item className="unactive" onClick={() => navigate(-1)}>Host Tips</Breadcrumb.Item>
                    <Breadcrumb.Item className="active">{`${hostDetail?.firstName} ${hostDetail?.lastName}`}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h5 className='fw-bold my-2'>Tip Details</h5>
            <Spin spinning={loading}>
                <Row className='mt-3 py-3' style={{ backgroundColor: "#e7ebe7", borderRadius: "10px" }}>
                    <Col xs={24} sm={24} md={6} lg={5} style={{ textAlign: "center" }}>
                        {hostDetail?.profilePic ?
                            <img src={`${fileUrl}${hostDetail?.profilePic}`} alt="avatar" type="square" size={30} />
                            : <Avatar size={64} icon={<UserOutlined />} style={{ width: "100px", height: "100px", fontSize: "80px" }} />
                        }

                    </Col>
                    <Col xs={24} sm={12} md={5} lg={10} className=''>
                        <div className="mt-4">
                            <p><span className='fw-bold'>Name:</span> {hostDetail?.firstName ? `${hostDetail?.firstName}${hostDetail?.lastName}` : '--'}</p>
                            <p><span className='fw-bold'>Email:</span> {hostDetail?.email ?? "--"}</p>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={10} lg={9}>
                        <div className="mt-4">
                            <p><span className='fw-bold'>TotalTips: </span>{location?.state?.totalTip}</p>
                        </div>
                    </Col>
                </Row>

                {/* table */}
                <div className="mt-3">
                    <Table
                        columns={columns}
                        dataSource={hostDetail?.allTipData}
                        scroll={{ x: "250px" }}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default HostTipUserView