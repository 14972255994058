import React, { useEffect, useState } from 'react'
import { postPromoterData } from '../components/CommonApiCall';
import { Button, Col, Row, Select, Table, message, Spin } from 'antd';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const start_month = moment().startOf('month')
const startOfMonth = moment(start_month._d).format('YYYY-MM-DD')
const end_month = moment().endOf('month');
const endOfMonth = moment(end_month._d).format('YYYY-MM-DD')
const defaultDate = { start_date: startOfMonth, end_date: endOfMonth }

function AffiliationDashboard() {
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState('');
    const [showData, setShowData] = useState(false);
    const [groupBy, setGroupBy] = useState('month');
    const [userData, setUserData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        position: ['bottomCenter'],
        size: 'small'
    });
    useEffect(() => {
        getData(defaultDate, "month")
    }, [])

    //** Called to fetch the users data record **//
    const getData = async (date, groupby) => {
        setLoading(true)
        let data = {
            start_date: date?.start_date,
            end_date: date?.end_date,
            group_by: groupby
        }
        console.log("data", data)
        // return false;
        const successCb = (response) => {
            setLoading(false)
            let data = response?.data?.data.map((item) => {
                return ({
                    revenue: item?.revenue_amount,
                    referrals: item?.referrals_count,
                    clicks: item?.clicks_count,
                    customers: item?.customers_count,
                    period: item?.period,
                })
            })
            setUserData(data);
            setShowData(true)
        }
        const failureCb = (response) => {
            setLoading(false)
            setUserData('')
            setPagination({
                current: 1,
                total: 0,
                pageSize: 5,
            });
        }
        postPromoterData(data, pagination?.current, pagination?.pageSize, "promoter/dashboard", "searchText", successCb, failureCb);
    }
    const dateFormat = (inputDate) => {
        var dateParts = inputDate.split('-');

        // Create a new date in 'DD-MM-YYYY' format
        var formattedDate = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
        return formattedDate
    }

    const onDateChange = (date, dateString) => {
        let startDate = dateFormat(dateString[0])
        let endDate = dateFormat(dateString[1])
        let data = {
            start_date: startDate,
            end_date: endDate,
        }
        setDateRange(data)
    };

    const handleSelectChange = (value) => {
        console.log("select", value)
        setGroupBy(value);
        if (dateRange?.start_date) {
            getData(dateRange, value);
        }
        else {
            let data = {
                start_date: startOfMonth,
                end_date: endOfMonth,
            }
            console.log("range check111", data, startOfMonth, endOfMonth)
            getData(data, value)
        }
    };

    const handleGetData = () => {
        if (!dateRange) {
            console.log("range check", startOfMonth, endOfMonth)
            let data = {
                start_date: startOfMonth,
                end_date: dayjs(endOfMonth, 'YYYY-MM-DD'),
                end_date: endOfMonth,
            }
            getData(data, groupBy)
        }
        getData(dateRange, groupBy);
    };

    const columns = [
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
        },
        {
            title: 'Customers',
            dataIndex: 'customers',
            key: 'customers',
        },
        {
            title: 'Referrals',
            dataIndex: 'referrals',
            key: 'referrals',
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
        },
        {
            title: 'Revenue',
            dataIndex: 'revenue',
            key: 'revenue',
        },
    ]

    return (
        <div className="Maincard p-3">
            {loading && <Spin size="large" className='loader' />}
            <div className='d-flex justify-content-between align-items-center'>
                <h4 className='fw-bold'>Dashboard</h4>
                <Button className='viewButton' onClick={() => window.open("https://workoutpartner.firstpromoter.com/users/sign_in", '_blank')}  >First Promoter</Button>
            </div>
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='my-3'>
                        <RangePicker
                            onChange={onDateChange}
                            allowClear={false}
                            defaultValue={[dayjs(startOfMonth, 'YYYY-MM-DD'), dayjs(endOfMonth, 'YYYY-MM-DD')]}
                        />
                        <Button className='ms-3 viewButton' onClick={handleGetData}>Submit</Button>
                    </div>
                    <div className='text-end'>
                        <span className='me-2 fw-bold'>Group By</span>
                        <Select
                            defaultValue={groupBy}
                            value={groupBy}
                            onChange={handleSelectChange}
                            options={[
                                {
                                    value: 'day',
                                    label: "Day"
                                },
                                {
                                    value: 'month',
                                    label: "Month"
                                },
                                {
                                    value: 'year',
                                    label: "Year"
                                },
                            ]}
                        >
                        </Select>
                    </div>
                </div>
                <div className='text-end mb-2'>
                </div>
            </div>
            {showData &&
                <div>
                    <Table
                        // rowSelection={rowSelection}
                        dataSource={userData}
                        columns={columns}
                        scroll={{ x: "250px" }}
                    // pagination={{
                    //     ...pagination,
                    //     onChange: handlePageChange,
                    // }} 
                    />
                </div>
            }
        </div>
    )
}

export default AffiliationDashboard