import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { BsFillCaretRightFill } from "react-icons/bs";
import { fileUrl } from "../constants/const";
const VideoPlayer = ({ videoUrl, thumbnailLink, streamType }) => {
    const [showPlayer, setShowPlayer] = useState(false);
    const elementRef = useRef(null);
    console.log("00000000000000000000000000000000000", videoUrl, thumbnailLink)
    const requestFullscreen = () => {
      setShowPlayer(true)
    };
    let videoLink;
    if (videoUrl) {
      if (streamType == "pre") videoLink = fileUrl + videoUrl;
      else videoLink = `${fileUrl}streams/` + videoUrl;
      console.log("videoLink", videoLink)
    }
    useEffect(() => {
      if (showPlayer) {
        if (elementRef.current) {
          if (elementRef.current.requestFullscreen) {
            elementRef.current.requestFullscreen();
          } else if (elementRef.current.mozRequestFullScreen) { // Firefox
            elementRef.current.mozRequestFullScreen();
          } else if (elementRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
            elementRef.current.webkitRequestFullscreen();
          } else if (elementRef.current.msRequestFullscreen) { // IE/Edge
            elementRef.current.msRequestFullscreen();
          }
          // setShowPlayer(true)
        }
      }
    }, [showPlayer])
    return (
      <div className="video-container d-flex justify-content-center flex-column align-items-center">
        {showPlayer ?
          <div className=''>
            {videoLink && <video controls autoPlay ref={elementRef} style={{ width: "9rem", height: "9.5rem" }}>
              <source src={videoLink} type="video/mp4" />
              Your browser does not support the video tag.
            </video>}
          </div>
          :
          <>
            <img src={thumbnailLink} alt="avatar" type="square" style={{ width: "9rem", height: "8rem", borderRadius: "10px" }} />
            {videoLink && <Button className="mt-2" onClick={() => requestFullscreen()}><BsFillCaretRightFill /> &nbsp;Play Video</Button>}
          </>
        }
      </div>
    );
  };

  export default VideoPlayer;