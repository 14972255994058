import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { mainAction } from "../redux/action/mainAction"
import { Avatar, Button, Table, Typography } from "antd"
import { fileUrl } from "../constants/const"
import moment from "moment"

const RewardPaid = () => {
    const dispatch = useDispatch()
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    })

    const getPaidRewrdList = (page, limit) => {
        let payload = {
            page: page,
            limit: limit
        }
        const successCb = (res) => {
            console.log("paidRewdList", res);
            setTableData(res?.data)
            setPagination((prevData) => ({
                ...prevData,
                total: res?.other?.total_entries
            })
            )
        }
        const failureCb = (res) => {
            console.log("errrr", res);
        }
        dispatch(mainAction(`admin/reward/get_paid_list?page=${page}&limit=${limit}`, 'get', payload, successCb, failureCb, true, false, ''))
    }

    useEffect(() => {
        getPaidRewrdList(pagination?.current, pagination?.pageSize)
    }, [])

    const columns = [
        {
            title: 'Host Name',
            dataIndex: 'name',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.hostDetail?.firstName ?
                            <div>
                                <Avatar src={`${fileUrl}${object?.hostDetail?.profilePic}`} />
                                <span className="px-2">
                                    {
                                        `${object?.hostDetail?.firstName} ${object?.hostDetail?.lastName}`
                                    }
                                </span>
                            </div>
                            : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Paypal Email',
            dataIndex: 'email',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">{object?.paid_to_paypal_email ? object?.paid_to_paypal_email : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Amount',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold">$ {object?.amount_paid ? object?.amount_paid : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Paid At',
            dataIndex: 'paid',
            render: (value, object, indx) => {
                return (
                    <>
                        <Typography className="fw-bold"> {object?.paidAt ? moment(object?.paidAt).format('MM-DD-YYYY') : '---'}</Typography>
                    </>
                )
            }
        },
        {
            title: 'Payment Status',
            render: (value, object, indx) => {
                let textColor = "#000"
                let status = ""
                if(object?.transaction_status.toUpperCase()=="PENDING"){
                    textColor= 'yellow'
                    status="INITIATED"
                }else if(object?.transaction_status.toUpperCase()=="SUCCESS"){
                    textColor="#43dd80"
                    status="SUCCESS"
                }
                return (
                    <>
                       <Typography className="fw-bold text-center py-1" style={{background:textColor}}>{object?.transaction_status?status:'---'}</Typography>
                    </>)
            },
        },
    ]

    const handlePageChange = (page, pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: page,
            pageSize: pageSize,
        }));
        getPaidRewrdList(page, pageSize)
    };
    return (
        <div className="">
            <h5 className="fw-bold">Paid Rewrds List</h5>
            <div className="">
                <div className="mt-3">
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        pagination={{
                            ...pagination,
                            onChange: handlePageChange,
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default RewardPaid