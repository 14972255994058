import { Button, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { BsArrowUpCircleFill, BsArrowDownCircleFill } from "react-icons/bs";
import VideoPlayer from '../components/VideoPlayer';
import thumbnail from '../assets/images/backgourndWorkput.png'
import { useLocation } from 'react-router-dom'; import { useNavigate } from 'react-router-dom';
import { CaretLeftOutlined } from '@ant-design/icons';
import { postPromoterData } from '../components/CommonApiCall';
import { fileUrl } from '../constants/const';


function StreamingAnalysisUser() {
  const [startDate, setStartDate] = useState(null);
  const [totalStats, setTotalStats] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState();
  const [showUserVideos, setShowUserVideos] = useState(false);
  const [userVideosData, setUserVideosData] = useState("");
  const location = useLocation();
  const userId = location?.state?.userId;
  console.log("userId Routing", location)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: ['bottomCenter'],
    size: 'small'
  });

  useEffect(() => {
    getData()
  }, [])

  //** Called to fetch the users data record **//
  const getData = async () => {
    console.log("first line in call ", userId)
    const now = new Date(); // current date and time
    const twentyFourHoursAgo = new Date(now - 24 * 60 * 60 * 1000); // 24 hours ago

    // Format dates as strings in the "YYYY-MM-DDTHH:mm:ss.sssZ" format
    const formattedStartDate = twentyFourHoursAgo.toISOString();
    const formattedEndDate = now.toISOString();

    setLoading(true)
    let data = {
      userId,
      "dates": [formattedStartDate, formattedEndDate]
    }
    console.log("data9999999999999999", data)
    // return false;
    const successCb = (response) => {
      console.log("77777777777777777777777777777777", response?.data?.results)
      setLoading(false)
      let data = response?.data?.results?.map((item) => {
        const updated = formatDate(item?.updatedAt)
        return ({
          totalAll: item?.data?.[0],
          video: item?.video,
          likes: item?.stat?.likeData,
          comments: item?.stat?.commentData,
          views: item?.stat?.viewData,
          shares: item?.stat?.shareData,
          streamType: item?.streamType,
          thumbnail: item?.thumbnail,
          updatedAt: updated,
          title: item?.desc,
        })
      })
      console.log("data tw=", data)
      setUserVideosData(data);
      setShowUserVideos(true)
    }
    const failureCb = (response) => {
      setLoading(false)
      // setUserData('')
      setPagination({
        current: 1,
        total: 0,
        pageSize: 5,
      });
    }
    postPromoterData(data, pagination?.current, pagination?.pageSize, "stats/videos", "searchText", successCb, failureCb);

    const successCb1 = (response) => {
      // console.log("77777777777777777777777777777777777", response?.data)
      setTotalStats(response?.data)
      setLoading(false)
    }
    const failureCb1 = (response) => {
      setLoading(false)
    }
    postPromoterData(data, pagination?.current, pagination?.pageSize, "stats/userTotal", "searchText", successCb1, failureCb1);

  }
  console.log("TTTTTTTTTTTTTTTTTTTTT", userVideosData)
  const navigate = useNavigate();

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);

    // Get day, month, and year
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Create the formatted date string
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  };

  return (
    <div className='Maincard p-3'>
      <Row className='mt-4 mb-1 justify-content-between align-items-center mb-2'>
        <h5 className='fw-bold'>Streaming Analysis</h5>
        <Button className='back d-flex jusitify-content-center align-items-center' onClick={() => navigate("/overview")}><CaretLeftOutlined />Back</Button>
      </Row>
      <div>
        <Row className='justify-content-between align-items-center'>
          <Col xs={12} sm={12} md={5} lg={5} className='Maincard p-3'>
            <p className='fw-bold'>Views</p>
            <div>{totalStats?.viewData.current} {(totalStats?.viewData.current > totalStats?.viewData.prev) ? <span className='icon_green ms-1'><BsArrowUpCircleFill /></span> : <span className='icon_red ms-1'><BsArrowDownCircleFill /></span>}</div>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} className='Maincard p-3'>
            <p className='fw-bold'>Likes</p>
            <div>{totalStats?.likeData.current} {(totalStats?.likeData.current > totalStats?.likeData.prev) ? <span className='icon_green ms-1'><BsArrowUpCircleFill /></span> : <span className='icon_red ms-1'><BsArrowDownCircleFill /></span>}</div>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} className='Maincard p-3'>
            <p className='fw-bold'>Comments</p>
            <div>{totalStats?.commentData.current}{(totalStats?.commentData.current > totalStats?.commentData.prev) ? <span className='icon_green ms-1'><BsArrowUpCircleFill /></span> : <span className='icon_red ms-1'><BsArrowDownCircleFill /></span>}</div>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} className='Maincard p-3'>
            <p className='fw-bold'>Shares</p>
            <div>{totalStats?.shareData.current} {(totalStats?.shareData.current > totalStats?.shareData.prev) ? <span className='icon_green ms-1'><BsArrowUpCircleFill /></span> : <span className='icon_red ms-1'><BsArrowDownCircleFill /></span>}</div>
          </Col>
        </Row>
        {userVideosData.length > 0 &&
          <>
            <h5 className='fw-bold my-3'>Videos</h5>
            <div className='Maincard p-2'>
              <Row className='justify-content-around'>
                {
                  userVideosData.length > 0 && userVideosData?.map((item) =>
                    <Col xs={24} sm={24} md={11} lg={11} className='p-3 m-1 Maincard d-flex flex-column justify-content-between'>
                      <Row className='p-2'>
                        <Col xs={24} sm={24} md={24} lg={10}>
                          {
                            console.log("item thumbnail", item?.thumbnail)
                          }
                          <VideoPlayer videoUrl={item?.video} thumbnailLink={`${fileUrl}${item?.thumbnail}`} streamType={item?.streamType} />

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <div className='p-1 pb-0'><span className='fw-bold'>Title: </span>{item?.title}</div>
                          <div className='p-1 pb-0' style={{ wordBreak: "break-word" }}><span className='fw-bold'>Uploaded On: </span>{item?.updatedAt}</div>

                        </Col>
                      </Row>
                      <div className='line_class'></div>
                      <Row className='mt-3'>
                        <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                          <div>View</div>
                          <div>{item?.views?.current} {(item?.views?.current > item?.views?.prev) ? <span className='icon_green ms-1'><BsArrowUpCircleFill /></span> : <span className='icon_red ms-1'><BsArrowDownCircleFill /></span>}</div>
                        </div>
                        <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                          <div>Likes</div>
                          <div>{item?.likes?.current} {(item?.likes?.current > item?.likes?.prev) ? <span className='icon_green ms-1'><BsArrowUpCircleFill /></span> : <span className='icon_red ms-1'><BsArrowDownCircleFill /></span>}</div>
                        </div>
                        <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                          <div>Comments</div>
                          <div>{item?.comments?.current} {(item?.comments?.current > item?.comments?.prev) ? <span className='icon_green ms-1'><BsArrowUpCircleFill /></span> : <span className='icon_red ms-1'><BsArrowDownCircleFill /></span>}</div>
                        </div>
                        <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                          <div>Share</div>
                          <div>{item?.shares?.current} {(item?.shares?.current > item?.shares?.prev) ? <span className='icon_green ms-1'><BsArrowUpCircleFill /></span> : <span className='icon_red ms-1'><BsArrowDownCircleFill /></span>}</div>
                        </div>
                      </Row>
                    </Col>
                  )
                }
                {/* <Col xs={24} sm={24} md={11} lg={11} className='p-3 m-1 Maincard d-flex flex-column align-items-between'>
              <Row className='p-2'>
                <Col xs={24} sm={24} md={24} lg={10}>
                  <VideoPlayer videoUrl={"video?.video"} thumbnailLink={thumbnail} streamType={"video"} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <div className='p-1 pb-0'><span className='fw-bold'>Title: </span>{"Squad"}</div>
                  <div className='p-1 pb-0' style={{ wordBreak: "break-word" }}><span className='fw-bold'>Uploaded On: </span>{"04-05-2023"}</div>
                </Col>
              </Row>
              <div className='line_class'></div>
              <Row className='mt-3'>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>View</div>
                  <div>{userVideosData?.view} <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>Likes</div>
                  <div>25 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>Comments</div>
                  <div>15 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
                <div className='w-100 d-flex justify-content-between align-items-center px-2'>
                  <div>Share</div>
                  <div>5 <span className='icon_span ms-1'><BsArrowUpCircleFill /></span></div>
                </div>
              </Row>
            </Col> */}
              </Row>
              {/* <  pagination={{
                  ...pagination,
                  onChange: handlePageChange,
                   }} 
                    /> */}
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default StreamingAnalysisUser