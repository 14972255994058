import { Col, Form, Input, Row, Switch, Select, Collapse, Spin, Tabs, Typography, Divider, Table, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { CheckOutlined, CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import AppUtils from '../Utils/AppUtils';
import { getDataCall, postCall } from '../components/CommonApiCall';
import barGraph1 from '../assets/images/Bar Chart 1.svg'

function DynamicSubscriptionView() {
    const [form] = Form.useForm();
    const [updateFields, setUpdateFields] = useState([]);
    const [loading, setLoading] = useState(false)
    const [updateBtnLoading, setUpdateBtnLoading] = useState(false)

    const [maxWorkoutMonth, setMaxWorkoutMonth] = useState(false)
    const [maxBroadcastMonth, setMaxBroadcastMonth] = useState(false)
    const [unlimitedHostJoinMonth, setUnlimitedHostJoinMonth] = useState(false)
    const [earnUnlimitedViewsCommision, setEarnUnlimitedViewsCommision] = useState(false)
    const [refresLoading, setRefresLoading] = useState(false)
    const [count, setCount] = useState({})

    const [duration, setDuration] = useState([
        {
            label: "Monthly",
            value: 'monthly'
        },
        {
            label: "Yearly",
            value: 'yearly'
        },
    ])
    const [gracePeriod, setGracePeriod] = useState([
        {
            label: "Month",
            value: 'month'
        },
        {
            label: "Days",
            value: 'days'
        },
    ])
    const columns_monthly = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            render: (_, record, index) => {
                return (
                    <label>{index + 1}</label>
                )
            }
        },
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
        {
            title: 'Coupon Description',
            dataIndex: 'coupon_desc',
            key: 'coupon_desc',
        },

    ];

    const columns_yearly = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            render: (_, record, index) => {
                return (
                    <label>{index + 1}</label>
                )
            }
        },
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
        {
            title: 'Coupon Description',
            dataIndex: 'coupon_desc',
            key: 'coupon_desc',
        }
    ];

    const [coupons_monthly, setCouponsMonthly] = useState([])
    const [coupons_yearly, setCouponsYearly] = useState([])
    const [subscriptionFeatures, setSubscriptionFeatures] = useState([])

    const handleGracePeriod = (value) => {
        console.log(`selected ${value}`);
    };

    const updateFuncFree = (values) => {
        console.log("values ", values)
    }

    // const getDynamicPlanData=()=>{

    // }

    const processData = (data, coupons) => {

        let monthly_plan_id = data.monthly.android_plan_id
        let yearly_plan_id = data.yearly.android_plan_id
        let monthly_coupons_data = coupons.filter((ite) => ite.coupon_plan_id === monthly_plan_id)
        let yearly_coupons_data = coupons.filter((ite) => ite.coupon_plan_id === yearly_plan_id)
        let dynamicfeatures = data?.dynamicfeatures ?? []

        setCouponsMonthly([...monthly_coupons_data])
        setCouponsYearly([...yearly_coupons_data])
        let fields = [
            {
                name: ["_id"],
                value: data?._id,
            },
            {
                name: ["plan_name"],
                value: data?.plan_name,
            },
            {
                name: ["desc"],
                value: data?.desc,
            },
            {
                name: ["android_plan_id_monthly"],
                value: data?.monthly?.android_plan_id,
            },
            {
                name: ["ios_plan_id_monthly"],
                value: data?.monthly?.ios_plan_id,
            },
            {
                name: ["grace_period_monthly"],
                value: data?.monthly?.grace_period
            },
            {
                name: ["grace_period_unit_monthly"],
                value: data?.monthly?.grace_period_unit,
            },
            {
                name: ["price_monthly"],
                value: `$${data?.monthly?.price}`,
            },
            {
                name: ["android_plan_id_yearly"],
                value: data?.yearly?.android_plan_id,
            },
            {
                name: ["ios_plan_id_yearly"],
                value: data?.yearly?.ios_plan_id,
            },
            {
                name: ["grace_period_yearly"],
                value: data?.yearly?.grace_period
            },
            {
                name: ["grace_period_unit_yearly"],
                value: data?.yearly?.grace_period_unit,
            },
            {
                name: ["price_yearly"],
                value: `$${data?.yearly?.price}`,
            }
        ]

        // set all features to form values
        if (dynamicfeatures.length > 0) {
            dynamicfeatures.map((ite) => {
                let key = ite.key
                let enabled = ite.enabled
                let quantity = ite.quantity
                let is_quantity = ite.is_quantity
                if (is_quantity) { // check if quantity set to any feature
                    // update switch value here

                    fields.push({
                        name: key,
                        value: enabled === "true"
                    })
                    // update input value here
                    fields.push({
                        name: `${key}_input`,
                        value: quantity
                    })
                } else {
                    // update switch value here

                    fields.push({
                        name: key,
                        value: enabled === "true"
                    })
                }
            })
        }
        AppUtils.showMessage("fields ", fields)
        setUpdateFields([...fields])
        setSubscriptionFeatures([...dynamicfeatures])
    }

    const getDynamicPlanData = async () => {
        setLoading(true)
        let pathName = getPlanName()
        const successCb = (response) => {
            AppUtils.showMessage("response ", response)
            setLoading(false)
            // setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     total: response?.data?.[0]?.totalResults,
            // }));
            let data = response.data.data
            AppUtils.showMessage("data ", data)
            let count = response.data.count
            let coupons = response.data.coupons
            processData(data, coupons)
            setCount({ ...count })

            // setData(datas)
        }
        const failureCb = (response) => {
            setLoading(false)
            // setData([])

        }
        getDataCall(1, 10, "", `admin/dynaminSubscription/${pathName}`, successCb, failureCb)
    }

    const getPlanName = () => {
        const pathname = window.location.href;
        // Split the pathname by '/' and filter out empty segments
        AppUtils.showMessage("pathName ", pathname)
        const segments = pathname.split('/').filter(segment => segment !== '');
        // Get the last segment
        const lastSegment = segments.length > 0 ? segments[segments.length - 1] : '';
        // AppUtils.showMessage("lastSegment ",lastSegment)
        return lastSegment
    }

    useEffect(() => {
        getDynamicPlanData()
    }, [form])



    const PlanMonthly = <div className=''>

        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Android Plan Id</label>
                <Form.Item name='android_plan_id_monthly' rules={[{ required: true, message: "Android plan should not be empty" }]} >
                    <Input placeholder="Enter android plan id" className='mt-2' type='text' disabled />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>IOS Plan Id</label>
                <Form.Item name='ios_plan_id_monthly' rules={[{ required: true, message: "IOS plan should not be empty" }]} >
                    <Input placeholder="Enter ios plan id" className='mt-2' type='text' disabled />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Grace Period</label>
                <Form.Item name='grace_period_monthly' rules={[{ required: true, message: "Grace period should not be empty" }]}>
                    <Input placeholder="Enter grace period" className='mt-2' type='number' disabled />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Grace Period Unit</label>
                <Form.Item name='grace_period_unit_monthly' rules={[{ required: true, message: "Grace period unit should not be empty" }]}>
                    <Select
                        className='mt-2'
                        defaultValue="month"
                        style={{
                            width: "100%",
                        }}
                        onChange={handleGracePeriod}
                        options={gracePeriod}
                        disabled
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>

            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Price</label>
                <Form.Item name='price_monthly' rules={[{ required: true, message: "Price should not be empty" }]}>
                    <Input placeholder="Enter price" className='mt-2' type='text' disabled />
                </Form.Item>
            </Col>
        </Row>
        <Row >
            <Divider />
        </Row>
        <Row >
            <p style={{ fontWeight: "bold" }}>Available Coupons</p>
        </Row>
        <Row >
            <Col md={24} lg={24} xs={24} sm={24} className='ps-2'>
                <Table
                    dataSource={coupons_monthly}
                    columns={columns_monthly}
                    pagination={false}
                    scroll={{ x: "100%" }}
                />
            </Col>
        </Row>

    </div>

    const PlanYearly = <div className=''>
        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Android Plan Id</label>
                <Form.Item name='android_plan_id_yearly' rules={[{ required: true, message: "Android plan should not be empty" }]} >
                    <Input placeholder="Enter android plan id" className='mt-2' type='text' disabled />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>IOS Plan Id</label>
                <Form.Item name='ios_plan_id_yearly' rules={[{ required: true, message: "IOS plan should not be empty" }]} >
                    <Input placeholder="Enter ios plan id" className='mt-2' type='text' disabled />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Grace Period</label>
                <Form.Item name='grace_period_yearly' rules={[{ required: true, message: "Grace period should not be empty" }]}>
                    <Input placeholder="Enter grace period" className='mt-2' type='number' disabled />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Grace Period Unit</label>
                <Form.Item name='grace_period_unit_yearly' rules={[{ required: true, message: "Grace period unit should not be empty" }]}>
                    <Select
                        className='mt-2'
                        defaultValue="month"
                        style={{
                            width: "100%",
                        }}
                        onChange={handleGracePeriod}
                        options={gracePeriod}
                        disabled
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>

            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Price</label>
                <Form.Item name='price_yearly' rules={[{ required: true, message: "Price should not be empty" }]}>
                    <Input placeholder="Enter price" className='mt-2' type='text' disabled />
                </Form.Item>
            </Col>
        </Row>
        <Row >
            <Divider />
        </Row>
        <Row >
            <p style={{ fontWeight: "bold" }}>Available Coupons</p>
        </Row>
        <Row >
            <Col md={24} lg={24} xs={24} sm={24} className='ps-2'>
                <Table
                    dataSource={coupons_yearly}
                    columns={columns_yearly}
                    pagination={false}
                    scroll={{ x: "100%" }}
                />
            </Col>
        </Row>
    </div>

    const items = [

        {
            key: '1',
            label: `Monthly`,
            children: <div>{PlanMonthly}</div>,
        },
        {
            key: '2',
            label: 'Yearly',
            children: <p>{PlanYearly}</p>,
        }
    ];


    return (
        <div className='Maincard p-3'>
            <Form onFinish={updateFuncFree} form={form} fields={updateFields}>
                <Spin spinning={loading}>
                    <Tabs
                        defaultActiveKey="1"
                        tabPosition={"top"}
                        items={[
                            {
                                label: `Plan Details`,
                                key: 1,

                                children: <>

                                    <Row className='mt-3'>
                                        <Col md={12} lg={12} xs={24} sm={24}>
                                            <label className='fw-bold'>Plan Name</label>
                                            <Form.Item name={'plan_name'} rules={[{ required: true, message: "Plan name should not be empty" }]}>
                                                <Input placeholder="Enter plan name" className='mt-2' disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={12} xs={24} sm={24}>
                                            <label className='fw-bold'>Description</label>
                                            <Form.Item name={'desc'} rules={[{ required: true, message: "Plan description should not be empty" }]}>
                                                <Input placeholder="Enter plan description" className='mt-2 ms-1' name='desc' disabled />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row className='mt-3'>
                                        <Col md={24} lg={24} xs={24} sm={24}>
                                            <Collapse className='p-2' items={items} defaultActiveKey={['1', '2']} />
                                        </Col>
                                    </Row>


                                    <Row className='mt-2'>
                                        {updateFields.length > 0 && (
                                            <Col md={24} lg={24} xs={24} sm={24}>
                                                <label className='fw-bold'>Features</label>
                                                <div className='p-3'>
                                                    {subscriptionFeatures.map((ite, index) => {
                                                        return (
                                                            <Row className='d-flex align-items-center justify-content-between p-1' key={index + 1}>
                                                                <Col xs={16} md={16} lg={16} className='' style={{ fontSize: "large" }}>
                                                                    {ite.name}
                                                                </Col>
                                                                <Col xs={8} md={8} lg={8} className='d-flex align-items-center ps-3'>
                                                                    {ite.is_quantity ? (
                                                                        <div className='d-flex flex-direction-row'>
                                                                            <Form.Item name={ite.key} >
                                                                                <Switch
                                                                                    checkedChildren={<CheckOutlined />}
                                                                                    unCheckedChildren={<CloseOutlined />}
                                                                                    disabled
                                                                                />

                                                                            </Form.Item>
                                                                            {ite.enabled === 'true' && (
                                                                                <>
                                                                                    <Form.Item name={`${ite.key}_input`} className='ms-2'>

                                                                                        <Input type='number' placeholder='Enter value' disabled />

                                                                                    </Form.Item>
                                                                                    <Form.Item name={`${ite.key}_checkbox`} className='ms-2'>

                                                                                        <Checkbox defaultChecked={ite.is_unlimited} disabled>Unlimited</Checkbox>

                                                                                    </Form.Item>
                                                                                </>
                                                                            )}

                                                                        </div>
                                                                    ) : (
                                                                        <Form.Item name={ite.key} >
                                                                            <Switch
                                                                                checkedChildren={<CheckOutlined />}
                                                                                unCheckedChildren={<CloseOutlined />}
                                                                                disabled
                                                                            />

                                                                        </Form.Item>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}

                                                </div>
                                            </Col>
                                        )}

                                    </Row>
                                </>
                                ,
                            },
                            {
                                label: `Earning Detail`,
                                key: 2,

                                children: <>

                                    <div className="mt-4 crads">
                                        <Row gutter={[30, 30]} className='d-flex justify-content-start'>
                                            <Col xs={24} sm={24} md={12} lg={12}>
                                                <div className="Maincard p-3">
                                                    <div className="d-flex justify-content-between">
                                                        <Typography className="total_paidText">Subscribed Users</Typography>
                                                        <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                            <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                            <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                        </Typography>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                                        <div className="amountPrt">
                                                            <Typography className="dollerAmount ms-2">{count?.subscribed_users ?? 0}
                                                            </Typography>
                                                        </div>
                                                        <img src={barGraph1} alt="bargraph_img" />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12}>
                                                <div className="Maincard p-3">
                                                    <div className="d-flex justify-content-between">
                                                        <Typography className="total_paidText">Earnings</Typography>
                                                        <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                            <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                            <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                        </Typography>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                                        <div className="amountPrt">
                                                            <Typography className="dollerAmount ms-2">${count?.total_earnings ?? 0}
                                                            </Typography>
                                                        </div>
                                                        <img src={barGraph1} alt="bargraph_img" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>


                                </>,
                            }
                        ]}
                    >

                    </Tabs>

                </Spin>
            </Form>
        </div>
    )
}

export default DynamicSubscriptionView