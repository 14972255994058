import { Button, Col, Form, Input, Row, Switch, Select, Collapse, Spin, message, Table, Tooltip,Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import AppUtils from '../Utils/AppUtils';
import { deleteCall, getDataCall } from '../components/CommonApiCall';
import { useNavigate } from 'react-router-dom';
import logoDel from '../assets/images/delete.png'

function DynamicSubscriptionList() {
    
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [delId, setDelId] = useState(null);
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        position: ['bottomCenter'],
        size: 'small'
    });



    const columns = [
        {
            title: 'Plan Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'desc',
            key: 'desc',
        },
        {
            title: 'Monthly Price',
            dataIndex: 'price_monthly',
            key: 'price_monthly',
            render: (_, record) => {
                return (
                    <>
                        <p>${record.price_monthly}</p>
                    </>)
            },
        },
        {
            title: 'Yearly Price',
            dataIndex: 'price_yearly',
            key: 'price_yearly',
            render: (_, record) => {
                return (
                    <>
                        <p>${record.price_yearly}</p>
                    </>)
            },
        },

        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            align: "center",
            render: (_, record) => {
                return (
                    <>
                        {/* <Button className='purpleButton px-4' onClick={()=>navigate(`/dynamic_subscription_view/${record._id}`)}>View</Button> */}
                        <Tooltip title="View Plan">
                            <>
                                <i class="bi bi-binoculars me-2" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => navigate(`/dynamic_subscription_view/${record._id}`)}></i>
                            </>
                        </Tooltip>
                        <Tooltip title="Edit Plan">
                            <>
                                <i class="bi bi-pencil-square me-2" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => navigate(`/dynamic_subscription_edit/${record._id}`)}></i>
                            </>
                        </Tooltip>
                        <Tooltip title="Delete Plan">
                            <>
                                <i class="bi bi-trash" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onDeleteClick(record._id)}></i>
                            </>
                        </Tooltip>
                    </>)
            },
        },
    ];
    const getData = async () => {
        setLoading(true)
        const successCb = (response) => {
            setLoading(false)
            // setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     total: response?.data?.[0]?.totalResults,
            // }));
            let reponseData = response?.data?.data??[]
            let datas = reponseData.map((item) => {
                return ({
                    _id: item?._id,
                    key: item?._id,
                    name: item?.plan_name,
                    desc: item.desc,
                    price_monthly: item.monthly.price,
                    price_yearly: item.yearly.price,
                })
            });

            setData(datas)
        }
        const failureCb = (response) => {
            setLoading(false)
            setData([])
            setPagination({
                current: 1,
                total: 0,
                pageSize: 10,
            });
        }
        getDataCall(pagination?.current, pagination?.pageSize, "", "admin/dynaminSubscription", successCb, failureCb)
    }

    const onDeleteClick = (id) => {
        console.log("id", id)
        setDelId(id)
        setOpenDeleteModal(true)
    }

    // Triggered on Change Page Number
    const handlePageChange = (page, pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: page,
            pageSize: pageSize,
        }));
    };

    const onDeleteOk = () => {
        setLoading(true)
        let url = 'admin/dynaminSubscription/'
        setOpenDeleteModal(false)
        const successCb = (res) => {
            setLoading(false)
            setDelId(null) 
            message.success("Subscription deleted successfully")
            setTimeout(()=>{
                getData()
            },200)
            
            
           
            
        }
        const failureCb = (res) => {
            AppUtils.showMessage("fail res",res)
            setLoading(false)
        }
        deleteCall(url, delId, successCb, failureCb)
    }


    useEffect(() => {
        getData();
    }, [pagination?.current, pagination?.pageSize])

    return (
        <div className='Maincard p-3'>
            <Form form={form} >
                <Spin spinning={loading}>
                    <Row>
                        <Col md={12} lg={12} xs={24} sm={24}>
                            <h4 className='fw-bold mt-2'>Dynamic Subscriptions</h4>
                        </Col>
                        <Col md={12} lg={12} xs={24} sm={24}>
                            <div className="mt-2 text-end">
                                <Button onClick={() => navigate('/dynamic_subscription_add')} type="ghost" className="button_common" style={{ width: "auto" }}>+ Add Plan</Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Table
                                dataSource={data}
                                columns={columns}
                                pagination={false}
                                scroll={{ x: "250px" }}
                            />
                            <Modal
                                centered
                                open={openDeleteModal}
                                onCancel={() => setOpenDeleteModal(false)}
                                footer={null}
                                width={350}
                            >
                                <div className='text-center'>
                                    <img src={logoDel} alt="delete_icon" style={{ width: "25%" }} />
                                </div>
                                <h5 className='text-center'>Delete Subscription</h5>
                                <p>Are you sure you want ot Delete this Subscription?</p>
                                <div>
                                    <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onDeleteOk} >Delete</Button>
                                    <Button danger type="primary" className='w-48 ' onClick={() => { setOpenDeleteModal(false); }}>Cancel</Button>
                                </div>
                            </Modal>
                        </Col>
                    </Row>

                </Spin>
            </Form>
        </div>
    )
}

export default DynamicSubscriptionList