import { Button, Row, Table, Spin } from 'antd';
import React, { useState, useEffect } from 'react'
import Search from 'antd/es/input/Search';
import moment from 'moment';
import { getPromoterData } from '../components/CommonApiCall';

const dataSource = [
  {
    key: '1',
    state: "test",
    email: "steve@check.com",
    uid: 55,
    referred: "Jack Son",
    campaign: "Referral Program Level 1",
    created: "25 Oct 2023"
  },
  {
    key: '2',
    state: "test",
    email: "steve@check.com",
    uid: 55,
    referred: "Jack Son",
    campaign: "Referral Program Level 1",
    created: "25 Oct 2023"
  },
  {
    key: '3',
    state: "test",
    email: "steve@check.com",
    uid: 55,
    referred: "Jack Son",
    campaign: "Referral Program Level 1",
    created: "25 Oct 2023"
  },
  {
    key: '4',
    state: "test",
    email: "steve@check.com",
    uid: 55,
    referred: "Jack Son",
    campaign: "Referral Program Level 1",
    created: "25 Oct 2023"
  },
  {
    key: '5',
    state: "test",
    email: "steve@check.com",
    uid: 55,
    referred: "Jack Son",
    campaign: "Referral Program Level 1",
    created: "25 Oct 2023"
  },
  {
    key: '6',
    state: "test",
    email: "steve@check.com",
    uid: 55,
    referred: "Jack Son",
    campaign: "Referral Program Level 1",
    created: "25 Oct 2023"
  },
  {
    key: '7',
    state: "test",
    email: "steve@check.com",
    uid: 55,
    referred: "Jack Son",
    campaign: "Referral Program Level 1",
    created: "25 Oct 2023"
  },
]

function Leads() {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: ['bottomCenter'],
    size: 'small'
  });

  useEffect(() => {
    getData();
  }, [pagination?.current, pagination?.pageSize, searchText])

  //** Called to fetch the users data record **//
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      //   setPagination((prevPagination) => ({
      //     ...prevPagination,
      //     total: response?.data?.totalResults,
      //   }));
      let data = response?.data?.data.map((item) => {
        return ({
          _id: item?.id,
          key: item?.id,
          email: item?.email,
          uid: item?.uid,
          state: item?.state,
          created: moment(item?.created_at).format("YYYY-MM-DD"),
        })
      })
      setUserData(data);
    }
    const failureCb = (response) => {
      setLoading(false)
      setUserData('')
      setPagination({
        current: 1,
        total: 0,
        pageSize: 5,
      });
    }
    getPromoterData(pagination?.current, pagination?.pageSize, "promoter/leads", searchText, successCb, failureCb);
  }


  // *** Page change ******//
  const handlePageChange = (page, pageSize) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page,
      pageSize: pageSize,
    }));
  };

  //*** to handle the Search Value ***//
  const handleSearch = (value) => {
    setSearchText(value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));
  };


  // columns for the table //
  const columns = [
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (record) => {
        return (
          <Button disabled style={{ backgroundColor: "rgb(98,64,201)", color: "white" }}>{record}</Button>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'UID',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      key: 'campaign',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
    },
  ];

  return (
    <div>
      {loading && <Spin size="large" className='loader' />}
      <div className='d-flex justify-content-between Maincard p-3 align-items-center mb-3'>
        <h4 className='fw-bold m-0'>Leads</h4>
        <Button className='viewButton' onClick={() => window.open("https://workoutpartner.firstpromoter.com/users/sign_in", '_blank')}  >First Promoter</Button>
      </div>
      <Table
        dataSource={userData}
        columns={columns}
        scroll={{ x: "250px" }}
      />
    </div>
  )
}

export default Leads