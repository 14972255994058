import './App.css';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './routes/ProtectedRoute';
import Login from './screens/Login';
import Home from './screens/Home';
import Header from './components/Header';
import Overview from './screens/Overview';
import MenuApp from './components/MenuApp';
import CustomNotification from './screens/CustomNotification';
import UserReporting from './screens/UserReporting';
import store from './store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <Provider store={store}>
      <div className='h-100'>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
        </Routes>
        <ToastContainer />
        {/* <Home /> */}
        {/* <Header/> */}
        {/* <Overview/> */}
        {/* <MenuApp/> */}
        {/* <CustomNotification/> */}
        {/* <UserReporting/> */}
      </div>
    </Provider>

  );
}

export default App;
